<template>
  <footer class="footer-footer">
    <div class="footer-container1">
      <div class="footer-about-section">
        <img :alt="imageAlt" :src="imageSrc" class="footer-image" />
        <span class="footer-content-title1 thq-body-small">
          <slot name="contentTitle1">
            <!--Default content for contentTitle1-->
            <div class="footer-fragment27">
              <span class="footer-text35">PlotPoint Limited</span>
            </div>
          </slot>
        </span>
        <span class="footer-content11 thq-body-small">
          <slot name="content1">
            <!--Default content for content1-->
            <div class="footer-fragment20">
              <span class="footer-content12">
                <span>Fourth Floor, 70 Baker Street, </span>
                <span>London, W1U 7DL</span>
                <br />
              </span>
            </div>
          </slot>
        </span>
        <span class="footer-content21 thq-body-small">
          <slot name="content2">
            <!--Default content for content2-->
            <div class="footer-fragment15">
              <span class="footer-content22">
                <span class="footer-text20">Phone: </span>
                <a href="tel:+442081547035" class="footer-link1">+</a>
                <a href="tel:+442081547035" class="footer-link2">
                  44 208 154 7035
                </a>
                <br />
              </span>
            </div>
          </slot>
        </span>
        <div class="footer-social-links">
          <svg viewBox="0 0 877.7142857142857 1024" class="thq-icon-small">
            <path
              d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"
            ></path></svg
          ><svg viewBox="0 0 877.7142857142857 1024" class="thq-icon-small">
            <path
              d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
            ></path></svg
          ><svg viewBox="0 0 950.8571428571428 1024" class="thq-icon-small">
            <path
              d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
            ></path></svg
          ><svg viewBox="0 0 877.7142857142857 1024" class="thq-icon-small">
            <path
              d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
            ></path></svg
          ><svg viewBox="0 0 1024 1024" class="thq-icon-small">
            <path
              d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"
            ></path>
          </svg>
        </div>
      </div>
      <div class="footer-container2 footer-column">
        <span class="footer-text10">
          <slot name="text">
            <!--Default content for text-->
            <div class="footer-fragment19">
              <span class="footer-text25">Learn More</span>
            </div>
          </slot>
        </span>
        <router-link to="/about" class="footer-navlink10">
          <slot name="text15">
            <!--Default content for text15-->
            <div class="footer-fragment25">
              <span class="footer-text33">Products</span>
            </div>
          </slot>
        </router-link>
        <router-link to="/private-credit">
          <slot name="text2">
            <!--Default content for text2-->
            <div class="footer-fragment10">
              <span class="footer-text13">Private Credit</span>
            </div>
          </slot>
        </router-link>
        <router-link to="/originators">
          <slot name="text3">
            <!--Default content for text3-->
            <div class="footer-fragment16">
              <span class="footer-text22">Partner with Us</span>
            </div>
          </slot>
        </router-link>
        <router-link to="/about" class="footer-navlink13">
          <slot name="text1">
            <!--Default content for text1-->
            <div class="footer-fragment21">
              <span class="footer-text29">About us</span>
            </div>
          </slot>
        </router-link>
        <router-link to="/contact">
          <slot name="text5">
            <!--Default content for text5-->
            <div class="footer-fragment12">
              <span class="footer-text15">Contact</span>
            </div>
          </slot>
        </router-link>
      </div>
      <div class="footer-container3 footer-column">
        <span class="footer-text11">
          <slot name="text6">
            <!--Default content for text6-->
            <div class="footer-fragment26">
              <span class="footer-text34">App</span>
            </div>
          </slot>
        </span>
        <router-link to="/coming-soon">
          <slot name="text7">
            <!--Default content for text7-->
            <div class="footer-fragment17">
              <span class="footer-text23">Download iOS app</span>
            </div>
          </slot>
        </router-link>
        <router-link to="/coming-soon">
          <slot name="text8">
            <!--Default content for text8-->
            <div class="footer-fragment11">
              <span class="footer-text14">Download Android app</span>
            </div>
          </slot>
        </router-link>
        <router-link to="/coming-soon">
          <slot name="text9">
            <!--Default content for text9-->
            <div class="footer-fragment23">
              <span class="footer-text31">Originator Portal</span>
            </div>
          </slot>
        </router-link>
      </div>
      <div class="footer-container4 footer-column">
        <span class="footer-text12">
          <slot name="text10">
            <!--Default content for text10-->
            <div class="footer-fragment28">
              <span class="footer-text36">Resources</span>
            </div>
          </slot>
        </span>
        <router-link to="/terms-conditions" class="footer-navlink18">
          <slot name="text11">
            <!--Default content for text11-->
            <div class="footer-fragment22">
              <span class="footer-text30">Funder Terms and Conditions</span>
            </div>
          </slot>
        </router-link>
        <router-link to="/disclaimer">
          <slot name="text13">
            <!--Default content for text13-->
            <div class="footer-fragment18">
              <span class="footer-text24">Disclaimer</span>
            </div>
          </slot>
        </router-link>
        <router-link to="/privacy-policy">
          <slot name="text14">
            <!--Default content for text14-->
            <div class="footer-fragment24">
              <span class="footer-text32">Privacy Policy</span>
            </div>
          </slot>
        </router-link>
      </div>
    </div>
    <div class="footer-footer-column">
      <div class="footer-second-footer-row">
        <span class="footer-content31">
          <slot name="content3">
            <!--Default content for content3-->
            <div class="footer-fragment14">
              <span class="footer-content32">
                <span class="footer-text17">
                  ©2024 PlotPoint Limited. All rights reserved.
                </span>
                <br />
                <span>
                  Registered in England &amp; Wales (Reg Number: 15914598).
                  <span v-html="rawob6f"></span>
                </span>
              </span>
            </div>
          </slot>
        </span>
        <div class="footer-footer-links">
          <router-link to="/disclaimer" class="footer-link13 thq-body-small">
            <slot name="link13">
              <!--Default content for link13-->
              <div class="footer-fragment13">
                <span class="footer-text16">Your Capital is at Risk</span>
              </div>
            </slot>
          </router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    imageSrc: {
      type: String,
      default: '/external/plotpoint-green-200h.png',
    },
    imageAlt: {
      type: String,
      default: 'image',
    },
  },
  data() {
    return {
      rawf7wl: ' ',
      rawob6f: ' ',
    }
  },
}
</script>

<style scoped>
.footer-footer {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
 
.footer-container1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.footer-about-section {
  gap: 0;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.footer-image {
  width: 120px;
  object-fit: cover;
  padding-bottom: var(--dl-space-space-unit);
}
 
.footer-content-title1 {
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  text-transform: capitalize;
}
 
.footer-content11 {
  font-family: "Bricolage Grotesque";
  padding-bottom: var(--dl-space-space-unit);
  text-transform: capitalize;
}
 
.footer-content21 {
  font-family: "Bricolage Grotesque";
  padding-bottom: var(--dl-space-space-unit);
}
 
.footer-social-links {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
 
.footer-container2 {
  gap: var(--dl-space-space-unit);
}
 
.footer-text10 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.footer-navlink10 {
  margin-bottom: 0px;
}
 
.footer-navlink13 {
  margin-bottom: 0px;
}
 
.footer-container3 {
  gap: var(--dl-space-space-unit);
}
 
.footer-text11 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.footer-container4 {
  gap: var(--dl-space-space-unit);
}
 
.footer-text12 {
  font-weight: 700;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.footer-footer-column {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.footer-second-footer-row {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
 
.footer-content31 {
  font-size: 14px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Bricolage Grotesque";
  line-height: 1.5;
}
 
.footer-footer-links {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.footer-link13 {
  font-size: 23px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Bricolage Grotesque";
  font-weight: 700;
}
 
.footer-fragment10 {
  display: contents;
}
 
.footer-text13 {
  display: inline-block;
}
 
.footer-fragment11 {
  display: contents;
}
 
.footer-text14 {
  display: inline-block;
}
 
.footer-fragment12 {
  display: contents;
}
 
.footer-text15 {
  display: inline-block;
}
 
.footer-fragment13 {
  display: contents;
}
 
.footer-text16 {
  display: inline-block;
}
 
.footer-fragment14 {
  display: contents;
}
 
.footer-content32 {
  display: inline-block;
}
 
.footer-text17 {
  font-weight: 700;
}
 
.footer-fragment15 {
  display: contents;
}
 
.footer-content22 {
  display: inline-block;
}
 
.footer-text20 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
 
.footer-link1 {
  text-decoration: underline;
}
 
.footer-link2 {
  text-decoration: underline;
}
 
.footer-fragment16 {
  display: contents;
}
 
.footer-text22 {
  display: inline-block;
}
 
.footer-fragment17 {
  display: contents;
}
 
.footer-text23 {
  display: inline-block;
}
 
.footer-fragment18 {
  display: contents;
}
 
.footer-text24 {
  display: inline-block;
}
 
.footer-fragment19 {
  display: contents;
}
 
.footer-text25 {
  display: inline-block;
}
 
.footer-fragment20 {
  display: contents;
}
 
.footer-content12 {
  display: inline-block;
}
 
.footer-fragment21 {
  display: contents;
}
 
.footer-text29 {
  display: inline-block;
}
 
.footer-fragment22 {
  display: contents;
}
 
.footer-text30 {
  display: inline-block;
}
 
.footer-fragment23 {
  display: contents;
}
 
.footer-text31 {
  display: inline-block;
}
 
.footer-fragment24 {
  display: contents;
}
 
.footer-text32 {
  display: inline-block;
}
 
.footer-fragment25 {
  display: contents;
}
 
.footer-text33 {
  display: inline-block;
}
 
.footer-fragment26 {
  display: contents;
}
 
.footer-text34 {
  display: inline-block;
}
 
.footer-fragment27 {
  display: contents;
}
 
.footer-text35 {
  display: inline-block;
}
 
.footer-fragment28 {
  display: contents;
}
 
.footer-text36 {
  display: inline-block;
}
 
@media(max-width: 991px) {
  .footer-footer {
    flex-direction: column;
  }
  .footer-container1 {
    flex-wrap: wrap;
    margin-right: 0px;
  }
  .footer-about-section {
    width: 300px;
  }
  .footer-second-footer-row {
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
}
 
@media(max-width: 767px) {
  .footer-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .footer-container1 {
    width: 100%;
    justify-content: space-between;
  }
  .footer-about-section {
    width: 100%;
    align-items: center;
    padding-bottom: var(--dl-space-space-unit);
  }
  .footer-social-links {
    align-self: center;
    justify-content: flex-start;
  }
  .footer-container2 {
    align-items: center;
  }
  .footer-container3 {
    align-items: center;
  }
  .footer-container4 {
    align-items: center;
  }
  .footer-navlink18 {
    text-align: center;
  }
  .footer-second-footer-row {
    gap: 0;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
  .footer-content31 {
    text-align: center;
    padding-top: var(--dl-space-space-unit);
  }
}
 
@media(max-width: 479px) {
  .footer-footer {
    padding: var(--dl-space-space-unit);
  }
  .footer-container1 {
    align-items: flex-start;
    margin-right: 0px;
    flex-direction: row;
    justify-content: space-between;
  }
  .footer-social-links {
    align-items: center;
    justify-content: center;
  }
  .footer-container2 {
    align-items: center;
  }
  .footer-container3 {
    align-items: center;
  }
  .footer-container4 {
    width: 100%;
    align-items: center;
  }
  .footer-footer-column {
    gap: 0;
  }
  .footer-second-footer-row {
    gap: 0;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .footer-content31 {
    text-align: center;
  }
  .footer-footer-links {
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
  }
}
</style>
