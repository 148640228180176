<template>
  <div class="private-credit-container10">
    <app-header></app-header>
    <div class="private-credit-hero hero-container section-container">
      <div class="private-credit-max-width1 max-width">
        <div class="private-credit-content4">
          <span class="private-credit-subtitle">Private credit</span>
          <h1 class="private-credit-title4">
            <span>Discover Asset-Backed </span>
            <span class="private-credit-text101">credit opportunties</span>
          </h1>
          <span class="private-credit-description">
            <span>
              PlotPoint offers sophisticated funders the opportunity to invest in
              secured private credit deals. Our platform connects you with
              experienced originators and property-backed investments that deliver
              strong returns while maintaining transparency and security.
            </span>
            <br />
          </span>
          <div class="private-credit-container11">
            <button class="button button-gradient">Get started</button>
            <button class="button button-transparent">Log in</button>
          </div>
        </div>
        <div class="private-credit-image1">
          <img
            alt="image"
            src="/privatecredit/funder1-600w.jpg"
            class="private-credit-hero-image1"
          />
          <img
            alt="image"
            src="/common/union-400w.png"
            class="private-credit-graphic-top"
          />
          <img
            alt="image"
            src="/common/group%2018-1200w.png"
            class="private-credit-image2"
          />
        </div>
      </div>
    </div>
    <div class="private-credit-section1 section-container">
      <div class="private-credit-max-width2 max-width">
        <div class="private-credit-content5">
          <span class="beforeHeading private-credit-text104">FOR FUNDERS</span>
          <h1 class="private-credit-text105">
            <span>Why </span>
            <span class="private-credit-text107">Private Credit?</span>
          </h1>
          <div class="private-credit-layout226 thq-section-padding">
            <div
              class="private-credit-max-width3 thq-grid-auto-300 thq-section-max-width"
            >
              <div class="thq-flex-column">
                <div class="private-credit-content6 thq-flex-column">
                  <h3 class="private-credit-title1 thq-heading-3">
                    Capital Preservation Product
                  </h3>
                  <span class="private-credit-description1 thq-body-small">
                    PlotPoint focuses on preserving your capital by offering
                    private credit opportunities backed by tangible assets. With
                    property-backed loans, your investment is secured, providing a
                    layer of protection while still delivering attractive returns.
                  </span>
                </div>
              </div>
              <div class="thq-flex-column">
                <div class="private-credit-content7 thq-flex-column">
                  <strong class="private-credit-title2 thq-heading-3">
                    Non Core Market Related
                  </strong>
                  <span class="private-credit-description2 thq-body-small">
                    Private credit offers a unique advantage by being largely
                    unaffected by traditional market movements. These investments
                    provide stability, making them an excellent option for those
                    looking to diversify away from volatile stock markets.
                  </span>
                </div>
              </div>
              <div class="thq-flex-column">
                <div class="private-credit-content8 thq-flex-column">
                  <strong class="private-credit-title31 thq-heading-3">
                    Diversification
                  </strong>
                  <span class="private-credit-description31 thq-body-small">
                    <span>
                      Diversify your portfolio with private credit opportunities.
                      By investing in real assets, such as property-backed loans,
                      you can reduce overall portfolio risk while enhancing your
                      exposure to a different asset class that complements your
                      existing investments.
                    </span>
                    <br />
                  </span>
                </div>
              </div>
              <div class="thq-flex-column">
                <div class="private-credit-content9 thq-flex-column">
                  <strong class="private-credit-title32 thq-heading-3">
                    Attractive Yields
                  </strong>
                  <span class="private-credit-description32 thq-body-small">
                    <span>
                      Enjoy stronger shorter term returns, with private credit
                      deals offering competitive yields. These short-term lending
                      opportunities are structured to provide reliable interest
                      payments, balancing risk and reward.
                    </span>
                    <br />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <span class="private-credit-link131 thq-body-small">
            Your Capital is at Risk.
          </span>
        </div>
      </div>
    </div>
    <div class="private-credit-section2 section-container">
      <div class="private-credit-max-width4 max-width">
        <div class="private-credit-content10">
          <span class="private-credit-text112">our track record</span>
          <h1 class="private-credit-text113">
            <span class="private-credit-text114">Over </span>
            <span class="private-credit-text115">
              Two Decades
              <span v-html="rawdxpb"></span>
            </span>
            <span class="private-credit-text116">of Combined Experience</span>
          </h1>
          <div class="private-credit-cards thq-section-padding">
            <div
              class="private-credit-max-width5 thq-grid-auto-300 thq-section-max-width"
            >
              <div class="private-credit-container12 thq-flex-column thq-card">
                <h2 class="private-credit-text117 thq-heading-2">
                  £100 million+
                </h2>
                <span class="private-credit-text118 thq-body-small">
                  Capital Deployed
                </span>
              </div>
              <div class="private-credit-container13 thq-flex-column thq-card">
                <h2 class="private-credit-text119 thq-heading-2">100</h2>
                <span class="private-credit-text120 thq-body-small">
                  Completed Deals
                </span>
              </div>
              <div class="private-credit-container14 thq-flex-column thq-card">
                <h2 class="private-credit-text121 thq-heading-2">9.2%</h2>
                <span class="private-credit-text122 thq-body-small">
                  Avg. Gross Interest Rate p.a
                </span>
              </div>
              <div class="private-credit-container15 thq-flex-column thq-card">
                <h2 class="private-credit-text123 thq-heading-2">40+ Years</h2>
                <span class="private-credit-text124 thq-body-small">
                  of Wealth Management Experience
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="private-credit-section3 section-container">
      <div class="private-credit-max-width6 max-width">
        <div class="private-credit-content11">
          <span class="beforeHeading private-credit-text125">
            explore plotpoint
          </span>
          <h1 class="private-credit-text126">
            <span>Key </span>
            <span class="private-credit-text128">Features</span>
          </h1>
          <ul class="private-credit-ul list">
            <li class="private-credit-li1 list-item">
              <span>
                <span class="private-credit-text130">
                  Transparency, Control &amp; Convenience
                </span>
                <br />
                <span>
                  Whether you&apos;re participating in a single deal or
                  diversifying across multiple opportunities, PlotPoint gives you
                  the flexibility to choose the deals that best meet your goals.
                </span>
              </span>
            </li>
            <li class="private-credit-li2 list-item">
              <span>
                <span class="private-credit-text134">
                  Advanced Risk Management
                </span>
                <br />
                <span>
                  Each Originator undertakes a comprehensive due diligence
                  process, ensuring that the opportunity is secure, asset-backed,
                  and vetted.
                </span>
              </span>
            </li>
            <li class="private-credit-li3 list-item">
              <span>
                <span class="private-credit-text138">Monitor your deals</span>
                <br />
                <span>
                  Keep track of your investment portfolio with regular updates on
                  performance, interest payments, and project milestones.
                </span>
              </span>
            </li>
          </ul>
        </div>
        <div class="private-credit-image3">
          <img
            alt="image"
            src="/privatecredit/funder2-1200w.png"
            class="private-credit-hero-image2"
          />
        </div>
      </div>
    </div>
    <case-study>
      <template v-slot:text>
        <div class="private-credit-fragment10">
          <span class="private-credit-text141">FEATURED PROJECT</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="private-credit-fragment11">
          <span class="private-credit-text142">
            Partnering with the borrower, we recently finalized a £600,000
            three-tranche, asset-backed bridge and development loan in Shoreditch,
            London.
          </span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="private-credit-fragment12">
          <span class="private-credit-text143">Gross Interest Rate p.a</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="private-credit-fragment13">
          <span class="private-credit-text144">Loan Term</span>
        </div>
      </template>
      <template v-slot:text4>
        <div class="private-credit-fragment14">
          <span class="private-credit-text145">Loan to Value (LTV)</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="private-credit-fragment15">
          <span class="private-credit-text146">Secured Asset-Backed Lending</span>
        </div>
      </template>
      <template v-slot:heading>
        <div class="private-credit-fragment16">
          <span class="private-credit-text147">
            <span>
              Bridge Loan in
              <span v-html="raww6jz"></span>
            </span>
            <span class="private-credit-text149">Shoreditch</span>
          </span>
        </div>
      </template>
      <template v-slot:heading1>
        <div class="private-credit-fragment17">
          <span class="private-credit-text150">10%</span>
        </div>
      </template>
      <template v-slot:heading2>
        <div class="private-credit-fragment18">
          <span class="private-credit-text151">11 Months</span>
        </div>
      </template>
      <template v-slot:heading3>
        <div class="private-credit-fragment19">
          <span class="private-credit-text152">46%</span>
        </div>
      </template>
      <template v-slot:heading4>
        <div class="private-credit-fragment20">
          <span class="private-credit-text153">First Charge and Debenture</span>
        </div>
      </template>
    </case-study>
    <div class="private-credit-section4 section-container">
      <div class="private-credit-max-width7 max-width">
        <div class="private-credit-image4">
          <img
            alt="image"
            src="/home/hero-4-1200w.jpg"
            class="private-credit-hero-image3"
          />
        </div>
        <div class="private-credit-content12">
          <span class="private-credit-text154">Start your journey today</span>
          <h1 class="private-credit-text155">How it works</h1>
          <div class="private-credit-step1">
            <div class="private-credit-number1">
              <span class="private-credit-text156">1</span>
            </div>
            <div class="private-credit-container16">
              <span class="private-credit-title5">Sign up &amp; Onboard</span>
              <span class="private-credit-text157">
                Complete your onboarding process easily through our platform on
                either Apple App Store, Google Play Store, or on the web,
                including all necessary ID verifications and compliance checks.
              </span>
            </div>
          </div>
          <div class="private-credit-step2">
            <div class="private-credit-number2">
              <span class="private-credit-text158">2</span>
            </div>
            <div class="private-credit-container17">
              <span class="private-credit-title6">Explore Vetted Deals</span>
              <span class="private-credit-text159">
                Browse fully-vetted private credit opportunities backed by
                property assets. We provide detailed summaries, risk analysis, and
                projected returns for each opportunity.
              </span>
            </div>
          </div>
          <div class="private-credit-step3">
            <div class="private-credit-number3">
              <span class="private-credit-text160">3</span>
            </div>
            <div class="private-credit-container18">
              <span class="private-credit-title7">Select &amp; Monitor</span>
              <span class="private-credit-text161">
                Once you&apos;ve selected a deal, you transfer your funds to a
                solicitor client account, and you can monitor your deal&apos;s
                performance and project updates through our dashboard.
              </span>
            </div>
          </div>
          <div class="private-credit-step4">
            <div class="private-credit-number4">
              <span class="private-credit-text162">4</span>
            </div>
            <div class="private-credit-container19">
              <span class="private-credit-title8">Earn Returns</span>
              <span class="private-credit-text163">
                Receive interest payments as per the deal terms, and rest assured
                that our team is actively monitoring the performance and security
                of your investment.
              </span>
            </div>
          </div>
          <span class="private-credit-link132 thq-body-small">
            <span class="private-credit-text164">
              Your Capital is at Risk.
              <span v-html="rawpit3"></span>
            </span>
            <br />
            <span>
              Past Performance is not an indicator of future performance.
            </span>
          </span>
        </div>
      </div>
    </div>
    <app-faq>
      <template v-slot:text2>
        <div class="private-credit-fragment21">
          <span class="private-credit-text167">lEARN MORE ABOUT PLOTPOINT </span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="private-credit-fragment22">
          <span class="private-credit-text168">
            How does PlotPoint match originators with funders?
          </span>
        </div>
      </template>
      <template v-slot:text4>
        <div class="private-credit-fragment23">
          <span class="private-credit-text169">
            PlotPoint uses a proprietary algorithm and thorough due diligence
            process to connect loan originators with suitable funders. Our team
            and platform evaluates project details, risk appetite, and funding
            preferences to ensure the best possible match, delivering quick and
            efficient connections.
          </span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="private-credit-fragment24">
          <span class="private-credit-text170">
            What types of loans do we offer on the platform?
          </span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="private-credit-fragment25">
          <span class="private-credit-text171">
            PlotPoint focuses on property-backed loans, including bridging finance
            and development finance. These loans are secured against real assets,
            offering both originators and funders security and flexibility in
            their financial projects.
          </span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="private-credit-fragment26">
          <span class="private-credit-text172">Is my investment secure?</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="private-credit-fragment27">
          <span class="private-credit-text173">
            While all investments on PlotPoint are secured by real, tangible
            assets and undergo rigorous due diligence—including property
            valuations and borrower assessments—your capital is at risk and not
            protected by the FSCS scheme. There is a possibility of losing some or
            all of your principal and interest.
          </span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="private-credit-fragment28">
          <span class="private-credit-text174">
            Do you have an iOS or Android app?
          </span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="private-credit-fragment29">
          <span class="private-credit-text175">
            Yes! PlotPoint offers a mobile app available on both iOS and Android,
            allowing you to monitor your investments or loans in real time,
            anytime, and anywhere.
          </span>
        </div>
      </template>
      <template v-slot:heading1>
        <div class="private-credit-fragment30">
          <span class="private-credit-text176">
            <span class="private-credit-text177">
              Frequently Asked
              <span v-html="rawy8pu"></span>
            </span>
            <br />
            <span class="private-credit-text179">Questions</span>
          </span>
        </div>
      </template>
    </app-faq>
    <app-footer>
      <template v-slot:text>
        <div class="private-credit-fragment31">
          <span class="private-credit-text180">Learn More</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="private-credit-fragment32">
          <span class="private-credit-text181">About us</span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="private-credit-fragment33">
          <span class="private-credit-text182">Private Credit</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="private-credit-fragment34">
          <span class="private-credit-text183">Partner with Us</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="private-credit-fragment35">
          <span class="private-credit-text184">Contact</span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="private-credit-fragment36">
          <span class="private-credit-text185">App</span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="private-credit-fragment37">
          <span class="private-credit-text186">Download iOS app</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="private-credit-fragment38">
          <span class="private-credit-text187">Download Android app</span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="private-credit-fragment39">
          <span class="private-credit-text188">Originator Portal</span>
        </div>
      </template>
      <template v-slot:link13>
        <div class="private-credit-fragment40">
          <span class="private-credit-text189">Your Capital is at Risk</span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="private-credit-fragment41">
          <span class="private-credit-text190">Resources</span>
        </div>
      </template>
      <template v-slot:text11>
        <div class="private-credit-fragment42">
          <span class="private-credit-text191">Funder Terms and Conditions</span>
        </div>
      </template>
      <template v-slot:text13>
        <div class="private-credit-fragment43">
          <span class="private-credit-text192">Disclaimer</span>
        </div>
      </template>
      <template v-slot:text14>
        <div class="private-credit-fragment44">
          <span class="private-credit-text193">Privacy Policy</span>
        </div>
      </template>
      <template v-slot:text15>
        <div class="private-credit-fragment45">
          <span class="private-credit-text194">Products</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="private-credit-fragment46">
          <span class="private-credit-content1">
            <span>Fourth Floor, 70 Baker Street, </span>
            <span>London, W1U 7DL</span>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content2>
        <div class="private-credit-fragment47">
          <span class="private-credit-content2">
            <span class="private-credit-text198">Phone: </span>
            <a href="tel:+442081547035" class="private-credit-link1">+</a>
            <a href="tel:+442081547035" class="private-credit-link2">
              44 208 154 7035
            </a>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content3>
        <div class="private-credit-fragment48">
          <span class="private-credit-content3">
            <span class="private-credit-text200">
              ©2024 PlotPoint Limited. All rights reserved.
            </span>
            <br />
            <span>
              Registered in England &amp; Wales (Reg Number: 15914598).
              <span v-html="rawwwia"></span>
            </span>
          </span>
        </div>
      </template>
      <template v-slot:contentTitle1>
        <div class="private-credit-fragment49">
          <span class="private-credit-text203">PlotPoint Limited</span>
        </div>
      </template>
    </app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import CaseStudy from '../components/case-study'
import AppFaq from '../components/faq'
import AppFooter from '../components/footer'

export default {
  name: 'PrivateCredit',
  props: {},
  components: {
    AppHeader,
    CaseStudy,
    AppFaq,
    AppFooter,
  },
  data() {
    return {
      rawdxpb: ' ',
      raww6jz: ' ',
      rawpit3: ' ',
      rawy8pu: ' ',
      rawwwia: ' ',
    }
  },
  metaInfo: {
    title: 'Private Credit - PlotPoint',
    meta: [
      {
        name: 'description',
        content:
          'Join PlotPoint’s network of funders. Invest in innovative property projects with flexible, hassle-free opportunities and expert support.',
      },
      {
        property: 'og:title',
        content: 'Private Credit - PlotPoint',
      },
      {
        property: 'og:description',
        content:
          'Join PlotPoint’s network of funders. Invest in innovative property projects with flexible, hassle-free opportunities and expert support.',
      },
    ],
  },
}
</script>

<style scoped>
.private-credit-container10 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.private-credit-hero {
  padding-bottom: var(--dl-space-space-twounits);
}
 
.private-credit-max-width1 {
  align-items: center;
}
 
.private-credit-content4 {
  flex: 0 0 auto;
  width: 45%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
 
.private-credit-subtitle {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
 
.private-credit-title4 {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1;
  margin-bottom: var(--dl-space-space-unit);
}
 
.private-credit-text101 {
  color: var(--dl-color-scheme-celadon);
}
 
.private-credit-description {
  color: var(--dl-color-scheme-white);
  font-size: 18px;
  font-family: "Bricolage Grotesque";
  line-height: 1.5;
  padding-bottom: var(--dl-space-space-unit);
}
 
.private-credit-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
 
.private-credit-image1 {
  position: relative;
  padding-bottom: var(--dl-space-space-fourunits);
}
 
.private-credit-hero-image1 {
  flex: 1;
  max-width: 550px;
  object-fit: cover;
  border-radius: 48px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
 
.private-credit-graphic-top {
  top: 0px;
  right: -170px;
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: 359px;
  max-height: 359px;
  object-fit: contain;
  object-position: center;
}
 
.private-credit-image2 {
  right: -170px;
  bottom: 0px;
  position: absolute;
  object-fit: contain;
}
 
.private-credit-section1 {
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
 
.private-credit-max-width2 {
  align-items: stretch;
}
 
.private-credit-content5 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-left: 0px;
  flex-direction: column;
  justify-content: center;
}
 
.private-credit-text105 {
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-unit);
}
 
.private-credit-text107 {
  color: var(--dl-color-scheme-medgreen);
}
 
.private-credit-layout226 {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
 
.private-credit-max-width3 {
  align-self: flex-start;
}
 
.private-credit-content6 {
  gap: 0;
}
 
.private-credit-title1 {
  text-align: center;
}
 
.private-credit-description1 {
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.private-credit-content7 {
  gap: 0;
}
 
.private-credit-title2 {
  text-align: center;
}
 
.private-credit-description2 {
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.private-credit-content8 {
  gap: 0;
}
 
.private-credit-title31 {
  text-align: center;
}
 
.private-credit-description31 {
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.private-credit-content9 {
  gap: 0;
}
 
.private-credit-title32 {
  text-align: center;
}
 
.private-credit-description32 {
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.private-credit-link131 {
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
}
 
.private-credit-section2 {
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: var(--dl-color-scheme-green100);
}
 
.private-credit-max-width4 {
  align-items: stretch;
}
 
.private-credit-content10 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
 
.private-credit-text112 {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
 
.private-credit-text113 {
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-unit);
}
 
.private-credit-text114 {
  color: var(--dl-color-scheme-white);
}
 
.private-credit-text115 {
  color: var(--dl-color-scheme-celadon);
}
 
.private-credit-text116 {
  color: var(--dl-color-scheme-white);
}
 
.private-credit-cards {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
 
.private-credit-max-width5 {
  display: flex;
  align-items: center;
}
 
.private-credit-container12 {
  gap: 0;
  color: var(--dl-color-theme-neutral-light);
  width: 25%;
  height: var(--dl-size-size-large);
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.private-credit-text117 {
  color: var(--dl-color-scheme-green80);
  height: 55px;
  font-size: 32px;
  text-align: center;
}
 
.private-credit-text118 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 12px;
  font-family: "Bricolage Grotesque";
}
 
.private-credit-container13 {
  gap: 0;
  color: var(--dl-color-theme-neutral-light);
  width: 25%;
  height: var(--dl-size-size-large);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.private-credit-text119 {
  color: var(--dl-color-scheme-green80);
  height: 55px;
}
 
.private-credit-text120 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 12px;
  font-family: "Bricolage Grotesque";
}
 
.private-credit-container14 {
  gap: 0;
  color: var(--dl-color-theme-neutral-light);
  width: 25%;
  height: var(--dl-size-size-large);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.private-credit-text121 {
  color: var(--dl-color-scheme-green80);
}
 
.private-credit-text122 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 12px;
  font-family: "Bricolage Grotesque";
}
 
.private-credit-container15 {
  gap: 0;
  color: var(--dl-color-theme-neutral-light);
  width: 25%;
  height: var(--dl-size-size-large);
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.private-credit-text123 {
  color: var(--dl-color-scheme-green80);
}
 
.private-credit-text124 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 12px;
  font-family: "Bricolage Grotesque";
}
 
.private-credit-section3 {
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: var(--dl-color-scheme-honeydew);
}
 
.private-credit-max-width6 {
  align-items: stretch;
}
 
.private-credit-content11 {
  width: 60%;
  display: flex;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
 
.private-credit-text126 {
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-unit);
}
 
.private-credit-text128 {
  color: var(--dl-color-scheme-medgreen);
}
 
.private-credit-ul {
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-threeunits);
  padding-bottom: 0px;
  list-style-type: square;
}
 
.private-credit-li1 {
  padding-bottom: var(--dl-space-space-unit);
}
 
.private-credit-text130 {
  font-weight: 700;
}
 
.private-credit-li2 {
  margin-bottom: var(--dl-space-space-unit);
}
 
.private-credit-text134 {
  font-weight: 700;
}
 
.private-credit-li3 {
  margin-bottom: var(--dl-space-space-unit);
}
 
.private-credit-text138 {
  font-weight: 700;
}
 
.private-credit-hero-image2 {
  width: 863px;
  object-fit: contain;
  margin-right: -250px;
  border-radius: 48px;
}
 
.private-credit-fragment10 {
  display: contents;
}
 
.private-credit-text141 {
  display: inline-block;
}
 
.private-credit-fragment11 {
  display: contents;
}
 
.private-credit-text142 {
  display: inline-block;
}
 
.private-credit-fragment12 {
  display: contents;
}
 
.private-credit-text143 {
  display: inline-block;
}
 
.private-credit-fragment13 {
  display: contents;
}
 
.private-credit-text144 {
  display: inline-block;
}
 
.private-credit-fragment14 {
  display: contents;
}
 
.private-credit-text145 {
  display: inline-block;
}
 
.private-credit-fragment15 {
  display: contents;
}
 
.private-credit-text146 {
  display: inline-block;
}
 
.private-credit-fragment16 {
  display: contents;
}
 
.private-credit-text147 {
  display: inline-block;
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
}
 
.private-credit-text149 {
  color: var(--dl-color-scheme-white);
}
 
.private-credit-fragment17 {
  display: contents;
}
 
.private-credit-text150 {
  display: inline-block;
}
 
.private-credit-fragment18 {
  display: contents;
}
 
.private-credit-text151 {
  display: inline-block;
}
 
.private-credit-fragment19 {
  display: contents;
}
 
.private-credit-text152 {
  display: inline-block;
}
 
.private-credit-fragment20 {
  display: contents;
}
 
.private-credit-text153 {
  display: inline-block;
}
 
.private-credit-section4 {
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: var(--dl-color-scheme-green80);
}
 
.private-credit-max-width7 {
  align-items: stretch;
}
 
.private-credit-image4 {
  width: 50%;
}
 
.private-credit-hero-image3 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 48px;
}
 
.private-credit-content12 {
  width: 40%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
 
.private-credit-text154 {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
 
.private-credit-text155 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.35;
}
 
.private-credit-step1 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
 
.private-credit-number1 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.private-credit-text156 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
 
.private-credit-container16 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.private-credit-title5 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.5;
}
 
.private-credit-text157 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
 
.private-credit-step2 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
 
.private-credit-number2 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.private-credit-text158 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
 
.private-credit-container17 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.private-credit-title6 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.5;
}
 
.private-credit-text159 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
 
.private-credit-step3 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
 
.private-credit-number3 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.private-credit-text160 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
 
.private-credit-container18 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.private-credit-title7 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.5;
}
 
.private-credit-text161 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
 
.private-credit-step4 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
 
.private-credit-number4 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.private-credit-text162 {
  font-size: 28px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 700;
  line-height: 1.5;
}
 
.private-credit-container19 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.private-credit-title8 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.5;
}
 
.private-credit-text163 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
 
.private-credit-link132 {
  color: var(--dl-color-scheme-white);
  font-style: normal;
  font-family: "Bricolage Grotesque";
}
 
.private-credit-text164 {
  font-weight: 700;
}
 
.private-credit-fragment21 {
  display: contents;
}
 
.private-credit-text167 {
  display: inline-block;
}
 
.private-credit-fragment22 {
  display: contents;
}
 
.private-credit-text168 {
  display: inline-block;
}
 
.private-credit-fragment23 {
  display: contents;
}
 
.private-credit-text169 {
  display: inline-block;
}
 
.private-credit-fragment24 {
  display: contents;
}
 
.private-credit-text170 {
  display: inline-block;
}
 
.private-credit-fragment25 {
  display: contents;
}
 
.private-credit-text171 {
  display: inline-block;
}
 
.private-credit-fragment26 {
  display: contents;
}
 
.private-credit-text172 {
  display: inline-block;
}
 
.private-credit-fragment27 {
  display: contents;
}
 
.private-credit-text173 {
  display: inline-block;
}
 
.private-credit-fragment28 {
  display: contents;
}
 
.private-credit-text174 {
  display: inline-block;
}
 
.private-credit-fragment29 {
  display: contents;
}
 
.private-credit-text175 {
  display: inline-block;
}
 
.private-credit-fragment30 {
  display: contents;
}
 
.private-credit-text176 {
  display: inline-block;
}
 
.private-credit-text177 {
  font-family: "Urbanist";
}
 
.private-credit-text179 {
  color: var(--dl-color-scheme-celadon);
  font-family: "Urbanist";
}
 
.private-credit-fragment31 {
  display: contents;
}
 
.private-credit-text180 {
  display: inline-block;
}
 
.private-credit-fragment32 {
  display: contents;
}
 
.private-credit-text181 {
  display: inline-block;
}
 
.private-credit-fragment33 {
  display: contents;
}
 
.private-credit-text182 {
  display: inline-block;
}
 
.private-credit-fragment34 {
  display: contents;
}
 
.private-credit-text183 {
  display: inline-block;
}
 
.private-credit-fragment35 {
  display: contents;
}
 
.private-credit-text184 {
  display: inline-block;
}
 
.private-credit-fragment36 {
  display: contents;
}
 
.private-credit-text185 {
  display: inline-block;
}
 
.private-credit-fragment37 {
  display: contents;
}
 
.private-credit-text186 {
  display: inline-block;
}
 
.private-credit-fragment38 {
  display: contents;
}
 
.private-credit-text187 {
  display: inline-block;
}
 
.private-credit-fragment39 {
  display: contents;
}
 
.private-credit-text188 {
  display: inline-block;
}
 
.private-credit-fragment40 {
  display: contents;
}
 
.private-credit-text189 {
  display: inline-block;
}
 
.private-credit-fragment41 {
  display: contents;
}
 
.private-credit-text190 {
  display: inline-block;
}
 
.private-credit-fragment42 {
  display: contents;
}
 
.private-credit-text191 {
  display: inline-block;
}
 
.private-credit-fragment43 {
  display: contents;
}
 
.private-credit-text192 {
  display: inline-block;
}
 
.private-credit-fragment44 {
  display: contents;
}
 
.private-credit-text193 {
  display: inline-block;
}
 
.private-credit-fragment45 {
  display: contents;
}
 
.private-credit-text194 {
  display: inline-block;
}
 
.private-credit-fragment46 {
  display: contents;
}
 
.private-credit-content1 {
  display: inline-block;
}
 
.private-credit-fragment47 {
  display: contents;
}
 
.private-credit-content2 {
  display: inline-block;
}
 
.private-credit-text198 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
 
.private-credit-link1 {
  text-decoration: underline;
}
 
.private-credit-link2 {
  text-decoration: underline;
}
 
.private-credit-fragment48 {
  display: contents;
}
 
.private-credit-content3 {
  display: inline-block;
}
 
.private-credit-text200 {
  font-weight: 700;
}
 
.private-credit-fragment49 {
  display: contents;
}
 
.private-credit-text203 {
  display: inline-block;
}
 
@media(max-width: 1200px) {
  .private-credit-text101 {
    color: var(--dl-color-scheme-celadon);
  }
  .private-credit-text107 {
    color: var(--dl-color-scheme-celadon);
  }
  .private-credit-text115 {
    color: var(--dl-color-scheme-celadon);
  }
  .private-credit-text116 {
    color: var(--dl-color-scheme-celadon);
  }
  .private-credit-text126 {
    font-family: Bricolage Grotesque;
  }
  .private-credit-text128 {
    color: var(--dl-color-scheme-medgreen);
  }
  .private-credit-text147 {
    font-size: 42px;
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 700;
    line-height: 1.25;
  }
}
 
@media(max-width: 991px) {
  .private-credit-content4 {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }
  .private-credit-image1 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .private-credit-hero-image1 {
    margin-bottom: 0px;
  }
  .private-credit-max-width2 {
    flex-direction: column;
  }
  .private-credit-content5 {
    width: 100%;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  .private-credit-text104 {
    text-align: center;
  }
  .private-credit-text105 {
    text-align: center;
  }
  .private-credit-content6 {
    align-items: center;
  }
  .private-credit-content7 {
    align-items: center;
  }
  .private-credit-content8 {
    align-items: center;
  }
  .private-credit-content9 {
    align-items: center;
  }
  .private-credit-max-width4 {
    flex-direction: column;
  }
  .private-credit-content10 {
    width: 100%;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  .private-credit-text112 {
    text-align: center;
  }
  .private-credit-text113 {
    text-align: center;
  }
  .private-credit-max-width5 {
    gap: var(--dl-space-space-twounits);
    flex-direction: row;
  }
  .private-credit-container12 {
    height: var(--dl-size-size-medium);
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .private-credit-text117 {
    height: auto;
    font-size: 24px;
  }
  .private-credit-container13 {
    height: var(--dl-size-size-medium);
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .private-credit-text119 {
    height: auto;
    font-size: 24px;
  }
  .private-credit-container14 {
    height: var(--dl-size-size-medium);
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .private-credit-text121 {
    font-size: 24px;
  }
  .private-credit-text122 {
    font-size: 10px;
  }
  .private-credit-container15 {
    height: var(--dl-size-size-medium);
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .private-credit-text123 {
    font-size: 24px;
  }
  .private-credit-text124 {
    text-align: center;
  }
  .private-credit-section3 {
    align-items: center;
  }
  .private-credit-max-width6 {
    flex-direction: column;
  }
  .private-credit-content11 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .private-credit-text125 {
    width: auto;
    align-self: center;
  }
  .private-credit-text126 {
    text-align: center;
  }
  .private-credit-ul {
    width: auto;
    align-self: center;
  }
  .private-credit-image3 {
    padding-bottom: 0px;
  }
  .private-credit-hero-image2 {
    width: 100%;
    height: 400px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .private-credit-max-width7 {
    flex-direction: column;
  }
  .private-credit-image4 {
    width: 100%;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .private-credit-hero-image3 {
    width: 100%;
    height: 300px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .private-credit-content12 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .private-credit-step1 {
    justify-content: center;
  }
  .private-credit-text161 {
    color: var(--dl-color-scheme-white);
    line-height: 1.5;
  }
  .private-credit-text163 {
    color: var(--dl-color-scheme-white);
    line-height: 1.5;
  }
}
 
@media(max-width: 767px) {
  .private-credit-hero {
    flex-direction: column;
  }
  .private-credit-max-width1 {
    flex-direction: column;
  }
  .private-credit-content4 {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
    margin-right: 0px;
  }
  .private-credit-hero-image1 {
    max-height: 400px;
    margin-right: 0px;
    border-top-left-radius: 48px;
    border-top-right-radius: 48px;
  }
  .private-credit-image2 {
    top: 66px;
    right: -137px;
    display: none;
  }
  .private-credit-content5 {
    width: 100%;
    margin-right: 0px;
  }
  .private-credit-text105 {
    font-family: Bricolage Grotesque;
  }
  .private-credit-text107 {
    color: var(--dl-color-scheme-celadon);
  }
  .private-credit-title1 {
    text-align: center;
  }
  .private-credit-link131 {
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 700;
  }
  .private-credit-content10 {
    width: 100%;
    margin-right: 0px;
  }
  .private-credit-text113 {
    font-family: Bricolage Grotesque;
  }
  .private-credit-text115 {
    color: var(--dl-color-scheme-celadon);
  }
  .private-credit-text116 {
    color: var(--dl-color-scheme-celadon);
  }
  .private-credit-max-width5 {
    flex-direction: column;
  }
  .private-credit-container12 {
    width: 100%;
  }
  .private-credit-container13 {
    width: 100%;
  }
  .private-credit-container14 {
    width: 100%;
  }
  .private-credit-container15 {
    width: 100%;
  }
  .private-credit-content11 {
    width: 100%;
    margin-right: 0px;
  }
  .private-credit-content12 {
    width: 100%;
    margin-right: 0px;
  }
}
 
@media(max-width: 479px) {
  .private-credit-title1 {
    text-align: center;
  }
  .private-credit-text117 {
    color: var(--dl-color-scheme-green80);
    font-size: 24px;
  }
  .private-credit-text130 {
    font-weight: 700;
  }
  .private-credit-hero-image2 {
    height: auto;
  }
  .private-credit-text147 {
    font-family: Bricolage Grotesque;
  }
  .private-credit-text149 {
    color: var(--dl-color-scheme-white);
  }
  .private-credit-image4 {
    padding-bottom: 0px;
  }
  .private-credit-link132 {
    align-self: center;
    text-align: center;
  }
}
</style>
