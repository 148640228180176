<template>
  <div class="home1-container1">
    <app-header></app-header>
    <div class="home1-hero hero-container section-container">
      <div class="home1-max-width1 max-width">
        <div class="home1-content4">
          <span class="home1-subtitle">
            Integrating funders and originators seamlessly
          </span>
          <h1 class="home1-title1">
            <span>
              Unlocking the future of
              <span v-html="rawr8ps"></span>
            </span>
            <span class="home1-text11">property-backed </span>
            <span class="home1-text12">credit</span>
          </h1>
          <span class="home1-description">
            PlotPoint connects loan originators and funders through a seamless,
            tech-driven platform. Access fully vetted, asset backed credit
            opportunities with transparency, control, and convenience.
          </span>
          <div class="home1-container2">
            <button class="button button-gradient">Get started</button>
            <button class="button button-transparent">Log in</button>
          </div>
        </div>
        <div class="home1-image1">
          <img
            alt="image"
            src="/home/hero-top-1200w.jpg"
            class="home1-hero-image1"
          />
          <img
            alt="image"
            src="/common/group%2018-1200w.png"
            class="home1-image2"
          />
          <img
            alt="image"
            src="/common/union-400h.png"
            class="home1-graphic-top"
          />
        </div>
      </div>
    </div>
    <div class="home1-section1 section-container">
      <div class="home1-max-width2 max-width">
        <div class="home1-image3">
          <img
            alt="image"
            src="/home/hero2-1200w.png"
            class="home1-hero-image2"
          />
        </div>
        <div class="home1-content5">
          <span class="beforeHeading home1-text13">FOR FUNDERS</span>
          <h1 class="home1-text14">
            <span>
              Secure, asset backed
              <span v-html="rawqhlz"></span>
            </span>
            <span class="home1-text16">opportunities</span>
          </h1>
          <span class="home1-text17">
            <span>
              Discover private credit opportunities backed by property assets.
              With PlotPoint, funders gain access to fully vetted deals, ensuring
              capital preservation.
            </span>
            <br />
            <br />
            <span class="home1-text21">Features for Funders:</span>
            <br />
          </span>
          <ul class="home1-ul1 list">
            <li class="home1-li1 list-item">
              <span>Up to date project updates</span>
            </li>
            <li class="home1-li2 list-item">
              <span class="home1-text24">Asset-backed security</span>
            </li>
            <li class="list-item">
              <span>Flexible investment opportunities</span>
            </li>
          </ul>
          <div class="home1-container3">
            <router-link
              to="/private-credit"
              class="home1-navlink1 button-secondary button bg-transparent"
            >
              Learn more
            </router-link>
          </div>
          <span class="home1-link131 thq-body-small">
            Your Capital is at Risk
          </span>
        </div>
      </div>
    </div>
    <div class="home1-section2 section-container">
      <div class="home1-max-width3 max-width">
        <div class="home1-content6">
          <span class="beforeHeading home1-text26">for originators</span>
          <h1 class="home1-text27">
            <span>Grow Your </span>
            <span class="home1-text29">Business</span>
          </h1>
          <span class="home1-text30">
            <span>
              PlotPoint streamlines the process for loan originators, providing
              access to a network of funders ready to finance your projects. Find
              new funding partners, report performance, and find the right funding
              partners through our intuitive platform.
            </span>
            <br />
            <br />
            <span class="home1-text34">Features for Originators:</span>
            <br />
          </span>
          <ul class="home1-ul2 list">
            <li class="home1-li4 list-item"><span>Fast onboarding</span></li>
            <li class="home1-li5 list-item">
              <span>Secure and pre-vetted funding matches</span>
            </li>
            <li class="list-item"><span>Flexible lending structures</span></li>
          </ul>
          <div class="home1-container4">
            <router-link
              to="/originators"
              class="home1-navlink2 button-secondary button bg-transparent"
            >
              See how
            </router-link>
          </div>
        </div>
        <div class="home1-image4">
          <img
            alt="image"
            src="/home/hero31-1200w.png"
            class="home1-hero-image3"
          />
        </div>
      </div>
    </div>
    <div class="home1-section3 section-container">
      <div class="home1-max-width4 max-width">
        <div class="home1-image5">
          <img
            alt="image"
            src="/home/hero-4-1200w.jpg"
            class="home1-hero-image4"
          />
        </div>
        <div class="home1-content7">
          <span class="home1-text39">Start your journey today</span>
          <h1 class="home1-text40">Open your account today</h1>
          <div class="home1-step1">
            <div class="home1-number1"><span class="home1-text41">1</span></div>
            <div class="home1-container5">
              <span class="home1-title2">Download the PlotPoint App</span>
              <span class="home1-text42">
                The PlotPoint app is available on Android, Apple App Store, and on
                the web
              </span>
            </div>
          </div>
          <div class="home1-step2">
            <div class="home1-number2"><span class="home1-text43">2</span></div>
            <div class="home1-container6">
              <span class="home1-title3">Create your free account</span>
              <span class="home1-text44">
                Fill in your personal details and start browsing current
                opportunities
              </span>
            </div>
          </div>
          <div class="home1-step3">
            <div class="home1-number3"><span class="home1-text45">3</span></div>
            <div class="home1-container7">
              <span class="home1-title4">Choose your deals</span>
              <span class="home1-text46">
                Select the deals you are interested in and register your interest
                to participate in the funding
              </span>
            </div>
          </div>
          <span class="home1-link132 thq-body-small">
            Your Capital is at Risk
          </span>
        </div>
      </div>
    </div>
    <recent-projects rootClassName="recent-projectsroot-class-name2">
      <template v-slot:heading>
        <div class="home1-fragment10">
          <span class="home1-text47">Recent Projects</span>
        </div>
      </template>
    </recent-projects>
    <app-faq>
      <template v-slot:text2>
        <div class="home1-fragment11">
          <span class="home1-text48">lEARN MORE ABOUT PLOTPOINT </span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="home1-fragment12">
          <span class="home1-text49">
            How does PlotPoint match originators with funders?
          </span>
        </div>
      </template>
      <template v-slot:text4>
        <div class="home1-fragment13">
          <span class="home1-text50">
            PlotPoint uses a proprietary algorithm and thorough due diligence
            process to connect loan originators with suitable funders. Our team
            and platform evaluates project details, risk appetite, and funding
            preferences to ensure the best possible match, delivering quick and
            efficient connections.
          </span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="home1-fragment14">
          <span class="home1-text51">
            What types of loans do we offer on the platform?
          </span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="home1-fragment15">
          <span class="home1-text52">
            PlotPoint focuses on property-backed loans, including bridging finance
            and development finance. These loans are secured against real assets,
            offering both originators and funders security and flexibility in
            their financial projects.
          </span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="home1-fragment16">
          <span class="home1-text53">Is my investment secure?</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="home1-fragment17">
          <span class="home1-text54">
            While all investments on PlotPoint are secured by real, tangible
            assets and undergo rigorous due diligence—including property
            valuations and borrower assessments—your capital is at risk and not
            protected by the FSCS scheme. There is a possibility of losing some or
            all of your principal and interest.
          </span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="home1-fragment18">
          <span class="home1-text55">Do you have an iOS or Android app?</span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="home1-fragment19">
          <span class="home1-text56">
            Yes! PlotPoint offers a mobile app available on both iOS and Android,
            allowing you to monitor your investments or loans in real time,
            anytime, and anywhere.
          </span>
        </div>
      </template>
      <template v-slot:heading1>
        <div class="home1-fragment20">
          <span class="home1-text57">
            <span class="home1-text58">
              Frequently Asked
              <span v-html="rawtbkj"></span>
            </span>
            <br />
            <span class="home1-text60">Questions</span>
          </span>
        </div>
      </template>
    </app-faq>
    <app-footer>
      <template v-slot:text>
        <div class="home1-fragment21">
          <span class="home1-text61">Learn More</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="home1-fragment22">
          <span class="home1-text62">About us</span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="home1-fragment23">
          <span class="home1-text63">Private Credit</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="home1-fragment24">
          <span class="home1-text64">Partner with Us</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="home1-fragment25">
          <span class="home1-text65">Contact</span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="home1-fragment26"><span class="home1-text66">App</span></div>
      </template>
      <template v-slot:text7>
        <div class="home1-fragment27">
          <span class="home1-text67">Download iOS app</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="home1-fragment28">
          <span class="home1-text68">Download Android app</span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="home1-fragment29">
          <span class="home1-text69">Originator Portal</span>
        </div>
      </template>
      <template v-slot:link13>
        <div class="home1-fragment30">
          <span class="home1-text70">Your Capital is at Risk</span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="home1-fragment31">
          <span class="home1-text71">Resources</span>
        </div>
      </template>
      <template v-slot:text11>
        <div class="home1-fragment32">
          <span class="home1-text72">Funder Terms and Conditions</span>
        </div>
      </template>
      <template v-slot:text13>
        <div class="home1-fragment33">
          <span class="home1-text73">Disclaimer</span>
        </div>
      </template>
      <template v-slot:text14>
        <div class="home1-fragment34">
          <span class="home1-text74">Privacy Policy</span>
        </div>
      </template>
      <template v-slot:text15>
        <div class="home1-fragment35">
          <span class="home1-text75">Products</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="home1-fragment36">
          <span class="home1-content1">
            <span>Fourth Floor, 70 Baker Street, </span>
            <span>London, W1U 7DL</span>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content2>
        <div class="home1-fragment37">
          <span class="home1-content2">
            <span class="home1-text79">Phone: </span>
            <a href="tel:+442081547035" class="home1-link1">+</a>
            <a href="tel:+442081547035" class="home1-link2">44 208 154 7035</a>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content3>
        <div class="home1-fragment38">
          <span class="home1-content3">
            <span class="home1-text81">
              ©2024 PlotPoint Limited. All rights reserved.
            </span>
            <br />
            <span>
              Registered in England &amp; Wales (Reg Number: 15914598).
              <span v-html="rawj0k1"></span>
            </span>
          </span>
        </div>
      </template>
      <template v-slot:contentTitle1>
        <div class="home1-fragment39">
          <span class="home1-text84">PlotPoint Limited</span>
        </div>
      </template>
    </app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import RecentProjects from '../components/recent-projects'
import AppFaq from '../components/faq'
import AppFooter from '../components/footer'

export default {
  name: 'Home1',
  props: {},
  components: {
    AppHeader,
    RecentProjects,
    AppFaq,
    AppFooter,
  },
  data() {
    return {
      rawr8ps: ' ',
      rawqhlz: ' ',
      rawtbkj: ' ',
      rawj0k1: ' ',
    }
  },
  metaInfo: {
    title:
      'Private Credit opportunities for Funders and Originators - PlotPoint',
    meta: [
      {
        property: 'og:title',
        content:
          'Private Credit opportunities for Funders and Originators - PlotPoint',
      },
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
  },
}
</script>

<style scoped>
.home1-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.home1-hero {
  padding-bottom: var(--dl-space-space-twounits);
}
 
.home1-max-width1 {
  align-items: center;
}
 
.home1-content4 {
  flex: 0 0 auto;
  width: 45%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
 
.home1-subtitle {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home1-title1 {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-unit);
}
 
.home1-text11 {
  color: var(--dl-color-scheme-celadon);
}
 
.home1-text12 {
  color: var(--dl-color-scheme-celadon);
}
 
.home1-description {
  color: var(--dl-color-scheme-white);
  font-size: 18px;
  font-family: "Bricolage Grotesque";
  line-height: 1.5;
  padding-bottom: var(--dl-space-space-twounits);
}
 
.home1-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
 
.home1-image1 {
  position: relative;
  padding-bottom: var(--dl-space-space-fourunits);
}
 
.home1-hero-image1 {
  flex: 1;
  max-width: 550px;
  object-fit: cover;
  border-radius: 48px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
 
.home1-image2 {
  right: 13px;
  bottom: 2px;
  position: absolute;
  object-fit: contain;
}
 
.home1-graphic-top {
  top: 0px;
  right: -170px;
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: 359px;
  max-height: 359px;
  object-fit: contain;
  object-position: center;
}
 
.home1-section1 {
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
}
 
.home1-max-width2 {
  align-items: stretch;
}
 
.home1-hero-image2 {
  object-fit: contain;
  margin-left: -290px;
  border-radius: 48px;
}
 
.home1-content5 {
  width: 500px;
  display: flex;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
 
.home1-text14 {
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-unit);
}
 
.home1-text16 {
  color: var(--dl-color-scheme-medgreen);
}
 
.home1-text17 {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0px;
}
 
.home1-text21 {
  font-weight: 700;
}
 
.home1-ul1 {
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-threeunits);
  padding-bottom: 0px;
  list-style-type: square;
}
 
.home1-li1 {
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.home1-li2 {
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.home1-text24 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
 
.home1-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
 
.home1-navlink1 {
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  margin-right: 0px;
  text-decoration: none;
}
 
.home1-link131 {
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-family: "Bricolage Grotesque";
  font-weight: 700;
}
 
.home1-section2 {
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: var(--dl-color-scheme-honeydew);
}
 
.home1-max-width3 {
  align-items: stretch;
}
 
.home1-content6 {
  width: 60%;
  display: flex;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
 
.home1-text27 {
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-unit);
}
 
.home1-text29 {
  color: var(--dl-color-scheme-medgreen);
}
 
.home1-text30 {
  font-size: 16px;
  line-height: 1.5;
}
 
.home1-text34 {
  font-weight: 700;
}
 
.home1-ul2 {
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-threeunits);
  padding-bottom: 0px;
  list-style-type: square;
}
 
.home1-li4 {
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.home1-li5 {
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.home1-container4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
 
.home1-navlink2 {
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  text-decoration: none;
}
 
.home1-hero-image3 {
  width: 863px;
  object-fit: contain;
  margin-right: -250px;
  border-radius: 48px;
}
 
.home1-section3 {
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: var(--dl-color-scheme-green80);
}
 
.home1-max-width4 {
  align-items: stretch;
}
 
.home1-image5 {
  width: 50%;
}
 
.home1-hero-image4 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 48px;
}
 
.home1-content7 {
  width: 40%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
 
.home1-text39 {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
 
.home1-text40 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.35;
  padding-bottom: var(--dl-space-space-unit);
}
 
.home1-step1 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
 
.home1-number1 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.home1-text41 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
 
.home1-container5 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.home1-title2 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.5;
}
 
.home1-text42 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
 
.home1-step2 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
 
.home1-number2 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.home1-text43 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
 
.home1-container6 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.home1-title3 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.5;
}
 
.home1-text44 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
 
.home1-step3 {
  display: flex;
  flex-direction: row;
}
 
.home1-number3 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.home1-text45 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
 
.home1-container7 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.home1-title4 {
  color: var(--dl-color-scheme-white);
  font-size: 24px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.5;
}
 
.home1-text46 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
 
.home1-link132 {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-family: "Bricolage Grotesque";
  font-weight: 700;
}
 
.home1-fragment10 {
  display: contents;
}
 
.home1-text47 {
  display: inline-block;
}
 
.home1-fragment11 {
  display: contents;
}
 
.home1-text48 {
  display: inline-block;
}
 
.home1-fragment12 {
  display: contents;
}
 
.home1-text49 {
  display: inline-block;
}
 
.home1-fragment13 {
  display: contents;
}
 
.home1-text50 {
  display: inline-block;
}
 
.home1-fragment14 {
  display: contents;
}
 
.home1-text51 {
  display: inline-block;
}
 
.home1-fragment15 {
  display: contents;
}
 
.home1-text52 {
  display: inline-block;
}
 
.home1-fragment16 {
  display: contents;
}
 
.home1-text53 {
  display: inline-block;
}
 
.home1-fragment17 {
  display: contents;
}
 
.home1-text54 {
  display: inline-block;
}
 
.home1-fragment18 {
  display: contents;
}
 
.home1-text55 {
  display: inline-block;
}
 
.home1-fragment19 {
  display: contents;
}
 
.home1-text56 {
  display: inline-block;
}
 
.home1-fragment20 {
  display: contents;
}
 
.home1-text57 {
  display: inline-block;
}
 
.home1-text58 {
  font-family: "Urbanist";
}
 
.home1-text60 {
  color: var(--dl-color-scheme-medgreen);
  font-family: "Urbanist";
}
 
.home1-fragment21 {
  display: contents;
}
 
.home1-text61 {
  display: inline-block;
}
 
.home1-fragment22 {
  display: contents;
}
 
.home1-text62 {
  display: inline-block;
}
 
.home1-fragment23 {
  display: contents;
}
 
.home1-text63 {
  display: inline-block;
}
 
.home1-fragment24 {
  display: contents;
}
 
.home1-text64 {
  display: inline-block;
}
 
.home1-fragment25 {
  display: contents;
}
 
.home1-text65 {
  display: inline-block;
}
 
.home1-fragment26 {
  display: contents;
}
 
.home1-text66 {
  display: inline-block;
}
 
.home1-fragment27 {
  display: contents;
}
 
.home1-text67 {
  display: inline-block;
}
 
.home1-fragment28 {
  display: contents;
}
 
.home1-text68 {
  display: inline-block;
}
 
.home1-fragment29 {
  display: contents;
}
 
.home1-text69 {
  display: inline-block;
}
 
.home1-fragment30 {
  display: contents;
}
 
.home1-text70 {
  display: inline-block;
}
 
.home1-fragment31 {
  display: contents;
}
 
.home1-text71 {
  display: inline-block;
}
 
.home1-fragment32 {
  display: contents;
}
 
.home1-text72 {
  display: inline-block;
}
 
.home1-fragment33 {
  display: contents;
}
 
.home1-text73 {
  display: inline-block;
}
 
.home1-fragment34 {
  display: contents;
}
 
.home1-text74 {
  display: inline-block;
}
 
.home1-fragment35 {
  display: contents;
}
 
.home1-text75 {
  display: inline-block;
}
 
.home1-fragment36 {
  display: contents;
}
 
.home1-content1 {
  display: inline-block;
}
 
.home1-fragment37 {
  display: contents;
}
 
.home1-content2 {
  display: inline-block;
}
 
.home1-text79 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
 
.home1-link1 {
  text-decoration: underline;
}
 
.home1-link2 {
  text-decoration: underline;
}
 
.home1-fragment38 {
  display: contents;
}
 
.home1-content3 {
  display: inline-block;
}
 
.home1-text81 {
  font-weight: 700;
}
 
.home1-fragment39 {
  display: contents;
}
 
.home1-text84 {
  display: inline-block;
}
 
@media(max-width: 1200px) {
  .home1-text11 {
    color: var(--dl-color-scheme-celadon);
  }
  .home1-text12 {
    color: var(--dl-color-scheme-celadon);
  }
  .home1-image2 {
    right: -78px;
    bottom: 3px;
  }
  .home1-graphic-top {
    top: -31px;
    right: -166px;
  }
  .home1-text16 {
    color: var(--dl-color-scheme-celadon);
  }
  .home1-text27 {
    font-family: Bricolage Grotesque;
  }
  .home1-text29 {
    color: var(--dl-color-scheme-medgreen);
  }
  .home1-text34 {
    font-weight: 700;
  }
}
 
@media(max-width: 991px) {
  .home1-content4 {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }
  .home1-image1 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home1-hero-image1 {
    margin-bottom: 0px;
  }
  .home1-max-width2 {
    flex-direction: column;
  }
  .home1-image3 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home1-hero-image2 {
    width: 100%;
    height: 400px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .home1-content5 {
    width: 100%;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home1-text13 {
    text-align: center;
  }
  .home1-text14 {
    text-align: center;
  }
  .home1-text17 {
    text-align: center;
  }
  .home1-ul1 {
    width: auto;
    align-self: center;
  }
  .home1-container3 {
    justify-content: center;
  }
  .home1-navlink1 {
    margin-right: 0px;
  }
  .home1-section2 {
    align-items: center;
  }
  .home1-max-width3 {
    flex-direction: column;
  }
  .home1-content6 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home1-text26 {
    width: auto;
    align-self: center;
  }
  .home1-text27 {
    text-align: center;
  }
  .home1-text30 {
    text-align: center;
  }
  .home1-text34 {
    font-weight: 700;
  }
  .home1-ul2 {
    width: auto;
    align-self: center;
  }
  .home1-container4 {
    justify-content: center;
  }
  .home1-image4 {
    padding-bottom: 0px;
  }
  .home1-hero-image3 {
    width: 100%;
    height: 400px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .home1-max-width4 {
    flex-direction: column;
  }
  .home1-image5 {
    width: 100%;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home1-hero-image4 {
    width: 100%;
    height: 300px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .home1-content7 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home1-step1 {
    justify-content: center;
  }
  .home1-text46 {
    color: var(--dl-color-scheme-white);
    line-height: 1.5;
  }
}
 
@media(max-width: 767px) {
  .home1-max-width1 {
    flex-direction: column;
  }
  .home1-content4 {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
    margin-right: 0px;
  }
  .home1-image1 {
    width: 100%;
    padding-bottom: 0px;
  }
  .home1-hero-image1 {
    width: 100%;
    max-width: 100%;
    max-height: 400px;
    margin-right: 0px;
    object-position: top;
    border-top-left-radius: 48px;
    border-top-right-radius: 48px;
  }
  .home1-image2 {
    top: -1px;
    right: -23px;
  }
  .home1-graphic-top {
    display: none;
  }
  .home1-image3 {
    padding-bottom: var(--dl-space-space-unit);
  }
  .home1-content5 {
    width: 100%;
    margin-right: 0px;
  }
  .home1-text14 {
    font-family: Bricolage Grotesque;
  }
  .home1-text16 {
    color: var(--dl-color-scheme-celadon);
  }
  .home1-text21 {
    font-weight: 700;
  }
  .home1-navlink1 {
    margin-right: 0px;
  }
  .home1-link131 {
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 700;
  }
  .home1-content6 {
    width: 100%;
    margin-right: 0px;
  }
  .home1-image5 {
    padding-bottom: 0px;
  }
  .home1-content7 {
    width: 100%;
    margin-right: 0px;
  }
}
 
@media(max-width: 479px) {
  .home1-max-width1 {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home1-content4 {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home1-subtitle {
    padding-top: var(--dl-space-space-twounits);
  }
  .home1-image1 {
    overflow: hidden;
    max-width: 100%;
    min-width: 100%;
    max-height: 500px;
    padding-bottom: 0px;
  }
  .home1-image2 {
    top: -87px;
    right: -53px;
  }
  .home1-graphic-top {
    top: -125px;
    right: -178px;
    display: none;
  }
  .home1-hero-image2 {
    height: auto;
  }
  .home1-text21 {
    font-weight: 700;
  }
  .home1-content6 {
    margin-bottom: 0px;
  }
  .home1-text27 {
    font-family: Bricolage Grotesque;
  }
  .home1-text29 {
    color: var(--dl-color-scheme-medgreen);
  }
  .home1-text34 {
    font-weight: 700;
  }
  .home1-container4 {
    padding-bottom: var(--dl-space-space-unit);
  }
  .home1-hero-image3 {
    height: auto;
  }
  .home1-image5 {
    padding-bottom: 0px;
  }
}
</style>
