<template>
  <div class="originators-container1">
    <app-header></app-header>
    <div class="originators-hero hero-container section-container">
      <div class="originators-max-width1 max-width">
        <div class="originators-content4">
          <span class="originators-subtitle">Originators</span>
          <h1 class="originators-title1">
            <span>Unlock reliable funding for your </span>
            <span class="originators-text11">projects</span>
          </h1>
          <span class="originators-description">
            PlotPoint matches you with capital requirements for projects in
            England &amp; Wales. Whether it&apos;s bridging or light construction,
            we move fast—submit your deal today and receive funding within 48
            hours, subject to due diligence.
            <span v-html="rawobv9"></span>
          </span>
        </div>
        <div class="originators-image1">
          <img
            alt="image"
            src="/partnerwithus/originator1-600w.jpg"
            class="originators-hero-image1"
          />
          <img
            alt="image"
            src="/common/group%2018-1200w.png"
            class="originators-image2"
          />
          <img
            alt="image"
            src="/common/union-400w.png"
            class="originators-graphic-top"
          />
        </div>
      </div>
    </div>
    <div class="originators-section1 section-container">
      <div class="originators-max-width2 max-width">
        <div class="originators-image3">
          <img
            alt="image"
            src="/partnerwithus/originator2-1200w.png"
            class="originators-hero-image2"
          />
        </div>
        <div class="originators-content5">
          <span class="beforeHeading originators-text12">FOR Originators</span>
          <h1 class="originators-text13">
            <span>
              Why Partner with
              <span v-html="rawmzya"></span>
            </span>
            <span class="originators-text15">PlotPoint?</span>
          </h1>
          <span class="originators-text16">
            <span>
              PlotPoint offers loan originators a reliable and streamlined way to
              secure the capital they need. Whether you’re working on bridging or
              light construction projects, we ensure the funding process is fast,
              transparent, and tailored to your needs.
            </span>
            <br />
            <br />
            <span class="originators-text20">Features for Originators:</span>
            <br />
          </span>
          <ul class="originators-ul1 list">
            <li class="originators-li1 list-item">
              <span class="originators-text22">
                <span class="originators-text23">Access to Funders</span>
                <br />
                <span>
                  Matching capital requirements with pre-agreed lending criteria,
                  ensuring you have the funding you need without surprises or
                  delays.
                </span>
                <br />
              </span>
            </li>
            <li class="originators-li2 list-item">
              <span class="originators-text27">
                <span class="originators-text28">Fast Drawdowns</span>
                <br />
                <span>
                  We know timing is everything in the property market. That&apos;s
                  why we ensure quick and efficient drawdowns, providing your
                  borrowers with the capital they need exactly when they need it.
                </span>
                <br />
              </span>
            </li>
            <li class="list-item">
              <span class="originators-text32">
                <span class="originators-text33">Pre-Agreed Mandate</span>
                <br />
                <span>
                  Receive funding based on pre-agreed lending criteria that align
                  perfectly with your origination channels, ensuring a streamlined
                  and predictable process for securing capital.
                </span>
                <br />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="originators-section2 section-container">
      <div class="originators-max-width3 max-width">
        <div class="originators-content6">
          <span class="beforeHeading originators-text37">for originators</span>
          <h1 class="originators-text38">
            <span>Our </span>
            <span class="originators-text40">Lending Criteria</span>
          </h1>
          <span class="originators-text41">
            <span>
              At PlotPoint, we primarily focus on residential-led property-backed
              opportunities in England, with a focus on:
            </span>
            <br />
          </span>
          <ul class="originators-ul2 list">
            <li class="originators-li4 list-item">
              <span class="originators-text44">
                <span class="originators-text45">1st Charge Bridge Lending</span>
                <br class="originators-text46" />
                <span>
                  We offer funding with a maximum LTV (Loan-to-Value) of 75%,
                  ensuring a balanced and secure loan structure.
                </span>
                <br />
              </span>
            </li>
            <li class="originators-li5 list-item">
              <span class="originators-text49">
                <span class="originators-text50">
                  Bridging and Light Construction
                </span>
                <br />
                <span>
                  Whether your project involves property bridging or light
                  construction, we provide the capital to help you succeed.
                </span>
                <br />
              </span>
            </li>
            <li class="originators-li6 list-item">
              <span class="originators-text54">
                <span class="originators-text55">Loan Terms</span>
                <br />
                <span>
                  Minimum loan term of 6 months, giving you the flexibility to
                  manage your project timelines with ease.
                </span>
              </span>
            </li>
            <li class="list-item">
              <span class="originators-text58">
                <span class="originators-text59">Loan Sizes</span>
                <br />
                <span>Up to £1 million</span>
                <br />
              </span>
            </li>
          </ul>
          <div class="originators-container2">
            <router-link
              to="/coming-soon"
              class="originators-navlink button-secondary button bg-transparent"
            >
              Get Started as an Originator
            </router-link>
          </div>
        </div>
        <div class="originators-image4">
          <img
            alt="image"
            src="/partnerwithus/originator3-1200w.png"
            class="originators-hero-image3"
          />
        </div>
      </div>
    </div>
    <div class="originators-section3 section-container">
      <div class="originators-max-width4 thq-section-max-width">
        <div class="originators-container3">
          <h2 class="originators-title2 thq-heading-2">Our Track Record</h2>
          <span class="originators-content7 thq-body-small">
            With over £400 million in assets under management, PlotPoint has built
            a reputation for reliability and efficiency. We work closely with
            family offices and have extensive experience in private credit and
            property-backed lending.
          </span>
        </div>
        <div class="originators-container4">
          <div class="originators-container5">
            <h2 class="originators-text63 thq-heading-2">£400 million+</h2>
            <span class="originators-text64 thq-body-small">
              Assets under Management
              <span v-html="rawgyqn"></span>
            </span>
          </div>
          <div class="originators-container6">
            <h2 class="originators-text65 thq-heading-2">40 Years</h2>
            <span class="originators-text66 thq-body-small">
              of Wealth Management Experience
            </span>
          </div>
          <div class="originators-container7">
            <h2 class="originators-text67 thq-heading-2">100</h2>
            <span class="originators-text68 thq-body-small">
              Deals completed by our team
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="originators-section4 section-container">
      <div class="originators-max-width5 thq-section-max-width">
        <div class="originators-container8">
          <cta-banner-originator
            rootClassName="cta-banner-originatorroot-class-name"
          >
            <template v-slot:text>
              <div class="originators-fragment10">
                <span class="originators-text69">get started</span>
              </div>
            </template>
            <template v-slot:button1>
              <div class="originators-fragment11">
                <span class="originators-text70">
                  Get started as an Originator
                </span>
              </div>
            </template>
            <template v-slot:heading>
              <div class="originators-fragment12">
                <span class="originators-text71">Sign up Today</span>
              </div>
            </template>
          </cta-banner-originator>
        </div>
      </div>
    </div>
    <app-footer>
      <template v-slot:text>
        <div class="originators-fragment13">
          <span class="originators-text72">Learn More</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="originators-fragment14">
          <span class="originators-text73">About us</span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="originators-fragment15">
          <span class="originators-text74">Private Credit</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="originators-fragment16">
          <span class="originators-text75">Partner with Us</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="originators-fragment17">
          <span class="originators-text76">Contact</span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="originators-fragment18">
          <span class="originators-text77">App</span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="originators-fragment19">
          <span class="originators-text78">Download iOS app</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="originators-fragment20">
          <span class="originators-text79">Download Android app</span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="originators-fragment21">
          <span class="originators-text80">Originator Portal</span>
        </div>
      </template>
      <template v-slot:link13>
        <div class="originators-fragment22">
          <span class="originators-text81">Your Capital is at Risk</span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="originators-fragment23">
          <span class="originators-text82">Resources</span>
        </div>
      </template>
      <template v-slot:text11>
        <div class="originators-fragment24">
          <span class="originators-text83">Funder Terms and Conditions</span>
        </div>
      </template>
      <template v-slot:text13>
        <div class="originators-fragment25">
          <span class="originators-text84">Disclaimer</span>
        </div>
      </template>
      <template v-slot:text14>
        <div class="originators-fragment26">
          <span class="originators-text85">Privacy Policy</span>
        </div>
      </template>
      <template v-slot:text15>
        <div class="originators-fragment27">
          <span class="originators-text86">Products</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="originators-fragment28">
          <span class="originators-content1">
            <span>Fourth Floor, 70 Baker Street, </span>
            <span>London, W1U 7DL</span>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content2>
        <div class="originators-fragment29">
          <span class="originators-content2">
            <span class="originators-text90">Phone: </span>
            <a href="tel:+442081547035" class="originators-link1">+</a>
            <a href="tel:+442081547035" class="originators-link2">
              44 208 154 7035
            </a>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content3>
        <div class="originators-fragment30">
          <span class="originators-content3">
            <span class="originators-text92">
              ©2024 PlotPoint Limited. All rights reserved.
            </span>
            <br />
            <span>
              Registered in England &amp; Wales (Reg Number: 15914598).
              <span v-html="rawqljs"></span>
            </span>
          </span>
        </div>
      </template>
      <template v-slot:contentTitle1>
        <div class="originators-fragment31">
          <span class="originators-text95">PlotPoint Limited</span>
        </div>
      </template>
    </app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import CtaBannerOriginator from '../components/cta-banner-originator'
import AppFooter from '../components/footer'

export default {
  name: 'Originators',
  props: {},
  components: {
    AppHeader,
    CtaBannerOriginator,
    AppFooter,
  },
  data() {
    return {
      rawobv9: ' ',
      rawmzya: ' ',
      rawgyqn: ' ',
      rawqljs: ' ',
    }
  },
  metaInfo: {
    title: 'Funding for Origiantors - PlotPoint',
    meta: [
      {
        name: 'description',
        content:
          'PlotPoint offers tailored funding for originators. Access quick, hassle-free loans with expert support and flexible terms to drive your property projects.',
      },
      {
        property: 'og:title',
        content: 'Funding for Origiantors - PlotPoint',
      },
      {
        property: 'og:description',
        content:
          'PlotPoint offers tailored funding for originators. Access quick, hassle-free loans with expert support and flexible terms to drive your property projects.',
      },
    ],
  },
}
</script>

<style scoped>
.originators-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.originators-hero {
  padding-bottom: var(--dl-space-space-twounits);
}
 
.originators-max-width1 {
  align-items: center;
}
 
.originators-content4 {
  flex: 0 0 auto;
  width: 45%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
 
.originators-subtitle {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
 
.originators-title1 {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1;
  margin-bottom: var(--dl-space-space-unit);
}
 
.originators-text11 {
  color: var(--dl-color-scheme-celadon);
}
 
.originators-description {
  color: var(--dl-color-scheme-white);
  font-size: 18px;
  font-family: "Bricolage Grotesque";
  line-height: 1.5;
}
 
.originators-image1 {
  position: relative;
  padding-bottom: var(--dl-space-space-fourunits);
}
 
.originators-hero-image1 {
  flex: 1;
  max-width: 550px;
  object-fit: cover;
  border-radius: 48px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
 
.originators-image2 {
  right: -170px;
  bottom: 0px;
  position: absolute;
  object-fit: contain;
}
 
.originators-graphic-top {
  top: 0px;
  right: -170px;
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: 359px;
  max-height: 359px;
  object-fit: contain;
  object-position: center;
}
 
.originators-section1 {
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
}
 
.originators-max-width2 {
  align-items: stretch;
}
 
.originators-hero-image2 {
  object-fit: contain;
  margin-left: -290px;
  border-radius: 48px;
}
 
.originators-content5 {
  width: 500px;
  display: flex;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
 
.originators-text13 {
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-unit);
}
 
.originators-text15 {
  color: var(--dl-color-scheme-medgreen);
}
 
.originators-text16 {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0px;
}
 
.originators-text20 {
  font-weight: 700;
}
 
.originators-ul1 {
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: 0px;
  padding-bottom: 0px;
  list-style-type: square;
}
 
.originators-li1 {
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.originators-text22 {
  line-height: 1.5;
}
 
.originators-text23 {
  font-weight: 700;
}
 
.originators-li2 {
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.originators-text27 {
  line-height: 1.5;
  margin-bottom: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.originators-text28 {
  font-weight: 700;
}
 
.originators-text32 {
  line-height: 1.5;
}
 
.originators-text33 {
  font-weight: 700;
}
 
.originators-section2 {
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: var(--dl-color-scheme-honeydew);
}
 
.originators-max-width3 {
  align-items: stretch;
}
 
.originators-content6 {
  width: 60%;
  display: flex;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
 
.originators-text38 {
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-unit);
}
 
.originators-text40 {
  color: var(--dl-color-scheme-medgreen);
}
 
.originators-text41 {
  font-size: 16px;
  line-height: 1.5;
}
 
.originators-ul2 {
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-threeunits);
  padding-bottom: 0px;
  list-style-type: square;
}
 
.originators-li4 {
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.originators-text44 {
  line-height: 1.5;
}
 
.originators-text45 {
  font-weight: 700;
}
 
.originators-text46 {
  font-weight: 700;
}
 
.originators-li5 {
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.originators-text49 {
  line-height: 1.5;
}
 
.originators-text50 {
  font-weight: 700;
}
 
.originators-li6 {
  padding-bottom: var(--dl-space-space-halfunit);
}
 
.originators-text54 {
  line-height: 1.5;
}
 
.originators-text55 {
  font-weight: 700;
}
 
.originators-text58 {
  line-height: 1.5;
}
 
.originators-text59 {
  font-weight: bold;
}
 
.originators-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
 
.originators-navlink {
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  text-decoration: none;
}
 
.originators-hero-image3 {
  width: 863px;
  object-fit: contain;
  margin-right: -250px;
  border-radius: 48px;
}
 
.originators-section3 {
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: var(--dl-color-scheme-green80);
}
 
.originators-max-width4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.originators-container3 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.originators-title2 {
  fill: var(--dl-color-scheme-celadon);
  color: var(--dl-color-scheme-celadon);
}
 
.originators-content7 {
  color: var(--dl-color-scheme-white);
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.originators-container4 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
 
.originators-container5 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
 
.originators-text63 {
  color: var(--dl-color-scheme-honeydew);
  text-align: center;
}
 
.originators-text64 {
  color: var(--dl-color-scheme-celadon);
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.originators-container6 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
 
.originators-text65 {
  color: var(--dl-color-scheme-honeydew);
  text-align: center;
}
 
.originators-text66 {
  color: var(--dl-color-scheme-celadon);
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.originators-container7 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
 
.originators-text67 {
  color: var(--dl-color-scheme-honeydew);
  text-align: center;
}
 
.originators-text68 {
  color: var(--dl-color-scheme-celadon);
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.originators-section4 {
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
}
 
.originators-max-width5 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.originators-container8 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.originators-fragment10 {
  display: contents;
}
 
.originators-text69 {
  display: inline-block;
}
 
.originators-fragment11 {
  display: contents;
}
 
.originators-text70 {
  display: inline-block;
}
 
.originators-fragment12 {
  display: contents;
}
 
.originators-text71 {
  display: inline-block;
}
 
.originators-fragment13 {
  display: contents;
}
 
.originators-text72 {
  display: inline-block;
}
 
.originators-fragment14 {
  display: contents;
}
 
.originators-text73 {
  display: inline-block;
}
 
.originators-fragment15 {
  display: contents;
}
 
.originators-text74 {
  display: inline-block;
}
 
.originators-fragment16 {
  display: contents;
}
 
.originators-text75 {
  display: inline-block;
}
 
.originators-fragment17 {
  display: contents;
}
 
.originators-text76 {
  display: inline-block;
}
 
.originators-fragment18 {
  display: contents;
}
 
.originators-text77 {
  display: inline-block;
}
 
.originators-fragment19 {
  display: contents;
}
 
.originators-text78 {
  display: inline-block;
}
 
.originators-fragment20 {
  display: contents;
}
 
.originators-text79 {
  display: inline-block;
}
 
.originators-fragment21 {
  display: contents;
}
 
.originators-text80 {
  display: inline-block;
}
 
.originators-fragment22 {
  display: contents;
}
 
.originators-text81 {
  display: inline-block;
}
 
.originators-fragment23 {
  display: contents;
}
 
.originators-text82 {
  display: inline-block;
}
 
.originators-fragment24 {
  display: contents;
}
 
.originators-text83 {
  display: inline-block;
}
 
.originators-fragment25 {
  display: contents;
}
 
.originators-text84 {
  display: inline-block;
}
 
.originators-fragment26 {
  display: contents;
}
 
.originators-text85 {
  display: inline-block;
}
 
.originators-fragment27 {
  display: contents;
}
 
.originators-text86 {
  display: inline-block;
}
 
.originators-fragment28 {
  display: contents;
}
 
.originators-content1 {
  display: inline-block;
}
 
.originators-fragment29 {
  display: contents;
}
 
.originators-content2 {
  display: inline-block;
}
 
.originators-text90 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
 
.originators-link1 {
  text-decoration: underline;
}
 
.originators-link2 {
  text-decoration: underline;
}
 
.originators-fragment30 {
  display: contents;
}
 
.originators-content3 {
  display: inline-block;
}
 
.originators-text92 {
  font-weight: 700;
}
 
.originators-fragment31 {
  display: contents;
}
 
.originators-text95 {
  display: inline-block;
}
 
@media(max-width: 1200px) {
  .originators-text11 {
    color: var(--dl-color-scheme-celadon);
  }
  .originators-text15 {
    color: var(--dl-color-scheme-celadon);
  }
  .originators-text38 {
    font-family: Bricolage Grotesque;
  }
  .originators-text40 {
    color: var(--dl-color-scheme-medgreen);
  }
  .originators-section3 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .originators-content7 {
    text-align: center;
  }
}
 
@media(max-width: 991px) {
  .originators-content4 {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }
  .originators-image1 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .originators-hero-image1 {
    margin-bottom: 0px;
  }
  .originators-max-width2 {
    flex-direction: column;
  }
  .originators-image3 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .originators-hero-image2 {
    width: 100%;
    height: 400px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .originators-content5 {
    width: 100%;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  .originators-text12 {
    text-align: center;
  }
  .originators-text13 {
    text-align: center;
    font-family: Bricolage Grotesque;
  }
  .originators-text15 {
    color: var(--dl-color-scheme-medgreen);
  }
  .originators-text16 {
    text-align: center;
  }
  .originators-ul1 {
    width: auto;
    align-self: center;
  }
  .originators-section2 {
    align-items: center;
  }
  .originators-max-width3 {
    flex-direction: column;
  }
  .originators-content6 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .originators-text37 {
    width: auto;
    align-self: center;
  }
  .originators-text38 {
    text-align: center;
  }
  .originators-text41 {
    text-align: center;
  }
  .originators-ul2 {
    width: auto;
    align-self: center;
  }
  .originators-container2 {
    justify-content: center;
  }
  .originators-image4 {
    padding-bottom: 0px;
  }
  .originators-hero-image3 {
    width: 100%;
    height: 400px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .originators-section3 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .originators-content7 {
    text-align: center;
  }
  .originators-text64 {
    text-align: center;
  }
  .originators-text66 {
    text-align: center;
  }
  .originators-section4 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
}
 
@media(max-width: 767px) {
  .originators-max-width1 {
    flex-direction: column;
  }
  .originators-content4 {
    width: 100%;
    padding-top: var(--dl-space-space-twounits);
    margin-right: 0px;
  }
  .originators-hero-image1 {
    max-height: 400px;
    margin-right: 0px;
    border-top-left-radius: 48px;
    border-top-right-radius: 48px;
  }
  .originators-image2 {
    top: 1px;
    right: -183px;
  }
  .originators-graphic-top {
    top: 38px;
    right: -15px;
    display: none;
  }
  .originators-content5 {
    width: 100%;
    margin-right: 0px;
  }
  .originators-text13 {
    font-family: Bricolage Grotesque;
  }
  .originators-text15 {
    color: var(--dl-color-scheme-celadon);
  }
  .originators-text20 {
    font-weight: 700;
  }
  .originators-content6 {
    width: 100%;
    margin-right: 0px;
  }
  .originators-title2 {
    text-align: center;
  }
  .originators-content7 {
    text-align: center;
  }
  .originators-container5 {
    align-self: flex-start;
  }
  .originators-text63 {
    align-self: center;
    text-align: center;
  }
  .originators-text64 {
    text-align: center;
  }
  .originators-container6 {
    align-self: flex-start;
  }
  .originators-text65 {
    align-self: center;
  }
  .originators-container7 {
    align-self: flex-start;
  }
  .originators-text67 {
    align-self: center;
  }
  .originators-text68 {
    text-align: center;
  }
}
 
@media(max-width: 479px) {
  .originators-max-width1 {
    flex-direction: column;
  }
  .originators-hero-image1 {
    max-height: 400px;
  }
  .originators-image2 {
    top: -1px;
    right: 6px;
  }
  .originators-graphic-top {
    display: none;
  }
  .originators-image3 {
    padding-bottom: 0px;
  }
  .originators-hero-image2 {
    height: auto;
  }
  .originators-navlink {
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 700;
  }
  .originators-hero-image3 {
    height: auto;
  }
  .originators-title2 {
    align-self: center;
    text-align: center;
  }
  .originators-container5 {
    padding: var(--dl-space-space-unit);
  }
  .originators-text63 {
    text-align: center;
  }
  .originators-text64 {
    text-align: center;
  }
  .originators-container6 {
    padding: var(--dl-space-space-unit);
    align-items: center;
  }
  .originators-text65 {
    text-align: center;
  }
  .originators-container7 {
    padding: var(--dl-space-space-unit);
  }
  .originators-text68 {
    text-align: center;
  }
}
</style>
