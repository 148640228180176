<template>
  <div class="cta-banner-originator-banner" v-bind:class="rootClassName">
    <span class="cta-banner-originator-text1 beforeHeading">
      <slot name="text">
        <!--Default content for text-->
        <div class="cta-banner-originator-fragment3">
          <span class="cta-banner-originator-text9">get started</span>
        </div>
      </slot>
    </span>
    <h1 class="cta-banner-originator-text2">
      <slot name="heading">
        <!--Default content for heading-->
        <div class="cta-banner-originator-fragment1">
          <span class="cta-banner-originator-text7">Sign up Today</span>
        </div>
      </slot>
    </h1>
    <span class="cta-banner-originator-text3">
      <span>
        Ready to get started? Join PlotPoint today and connect with funders for
        your property-backed projects.
      </span>
      <span><span v-html="rawdovh"></span></span>
    </span>
    <div class="cta-banner-originator-btns">
      <router-link
        to="/coming-soon"
        class="cta-banner-originator-navlink button button-gradient"
      >
        <span class="cta-banner-originator-text6">
          <slot name="button1">
            <!--Default content for button1-->
            <div class="cta-banner-originator-fragment2">
              <span class="cta-banner-originator-text8">
                Get started as an Originator
              </span>
            </div>
          </slot>
        </span>
      </router-link>
    </div>
    <img :alt="imageAlt" :src="imageSrc" class="cta-banner-originator-image1" />
    <img :alt="imageAlt" :src="imageSrc" class="cta-banner-originator-image2" />
  </div>
</template>

<script>
export default {
  name: 'CTABannerOriginator',
  props: {
    imageSrc: {
      type: String,
      default: '/common/group%2018-300h.png',
    },
    imageAlt: {
      type: String,
      default: 'image',
    },
    rootClassName: String,
  },
  data() {
    return {
      rawdovh: ' ',
    }
  },
}
</script>

<style scoped>
.cta-banner-originator-banner {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  position: relative;
  align-items: center;
  border-radius: 48px;
  flex-direction: column;
  background-color: var(--dl-color-scheme-green100);
}
 
.cta-banner-originator-text1 {
  color: var(--dl-color-scheme-honeydew);
  z-index: 100;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.cta-banner-originator-text2 {
  color: var(--dl-color-scheme-white);
  z-index: 100;
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
}
 
.cta-banner-originator-text3 {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  width: auto;
  z-index: 100;
  align-self: center;
  text-align: center;
  padding-left: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: 0px;
}
 
.cta-banner-originator-btns {
  flex: 0 0 auto;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
 
.cta-banner-originator-navlink {
  z-index: 100;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
 
.cta-banner-originator-image1 {
  top: 33px;
  right: -2px;
  width: 195px;
  height: 275px;
  opacity: 0.4;
  z-index: 1;
  position: absolute;
  object-fit: contain;
}
 
.cta-banner-originator-image2 {
  top: 19px;
  left: 2px;
  width: 195px;
  height: 275px;
  opacity: 0.4;
  z-index: 1;
  position: absolute;
  transform: rotate(180deg);
  object-fit: contain;
}
 
.cta-banner-originator-fragment1 {
  display: contents;
}
 
.cta-banner-originator-text7 {
  display: inline-block;
}
 
.cta-banner-originator-fragment2 {
  display: contents;
}
 
.cta-banner-originator-text8 {
  display: inline-block;
}
 
.cta-banner-originator-fragment3 {
  display: contents;
}
 
.cta-banner-originator-text9 {
  display: inline-block;
}
 

 
@media(max-width: 991px) {
  .cta-banner-originator-navlink {
    text-align: center;
  }
  .cta-banner-originator-text6 {
    text-align: center;
  }
}
 
@media(max-width: 767px) {
  .cta-banner-originator-banner {
    padding: var(--dl-space-space-twounits);
  }
  .cta-banner-originator-image1 {
    top: 55px;
    right: 1px;
    width: var(--dl-size-size-large);
    height: auto;
  }
  .cta-banner-originator-image2 {
    top: 30px;
    left: 0px;
    right: 0px;
    height: auto;
  }
}
 
@media(max-width: 479px) {
  .cta-banner-originator-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .cta-banner-originator-text2 {
    font-size: 36px;
    text-align: center;
  }
  .cta-banner-originator-image1 {
    top: 30px;
    right: 1px;
    width: var(--dl-size-size-large);
  }
  .cta-banner-originator-image2 {
    width: var(--dl-size-size-large);
  }
}
</style>
