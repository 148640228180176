<template>
  <div class="products-container1">
    <app-header></app-header>
    <div class="products-hero hero-container section-container">
      <div class="products-max-width1 max-width">
        <div class="products-content4">
          <span class="products-subtitle subtitle-top">OUR PRODUCTS</span>
          <h1 class="products-title4">
            <span>A range of funding products for </span>
            <span class="products-text11">every project</span>
          </h1>
          <span class="products-description">
            <span>
              PlotPoint offers sophisticated funders the opportunity to invest in
              secured private credit deals. Our platform connects you with
              experienced originators and property-backed investments that deliver
              strong returns while maintaining transparency and security.
            </span>
            <br />
          </span>
        </div>
        <div class="products-image1">
          <img
            alt="image"
            src="/products/homes1-1200w.jpg"
            class="products-hero-image1"
          />
          <img
            alt="image"
            src="/common/group%2018-1200w.png"
            class="products-image2"
          />
        </div>
      </div>
    </div>
    <div class="products-section1 section-container">
      <div class="products-max-width2 max-width">
        <div class="products-content5">
          <span class="beforeHeading products-text14">
            Tailored Finance Solutions for Every Need
          </span>
          <h1 class="products-text15">
            <span>Our </span>
            <span class="products-text17">Funding Products</span>
          </h1>
          <div class="products-layout226 thq-section-padding">
            <div
              class="products-max-width3 thq-grid-auto-300 thq-section-max-width"
            >
              <div class="thq-flex-column">
                <div class="products-content6 thq-flex-column">
                  <h3 class="products-title1 thq-heading-3">Bridging Finance</h3>
                  <span class="products-description1 thq-body-small">
                    Quick, short-term funding designed for both originators and
                    intermediaries. Enjoy a simple process, fast decisions, and
                    rapid funds availability.
                  </span>
                </div>
              </div>
              <div class="thq-flex-column">
                <div class="products-content7 thq-flex-column">
                  <strong class="products-title2 thq-heading-3">
                    Refurbishment Loans
                  </strong>
                  <span class="products-description2 thq-body-small">
                    Flexible financing for projects of every scale—from light
                    updates to heavy transformations. Perfect for originators and
                    intermediaries looking to revitalize properties.
                  </span>
                </div>
              </div>
              <div class="thq-flex-column">
                <div class="products-content8 thq-flex-column">
                  <strong class="products-title31 thq-heading-3">
                    Development Loans
                  </strong>
                  <span class="products-description31 thq-body-small">
                    <span>
                      Exclusive to intermediaries, our development loans provide
                      reliable, flexible financing to support every stage of your
                      project—from planning to completion.
                    </span>
                    <br />
                  </span>
                </div>
              </div>
              <div class="thq-flex-column">
                <div class="products-content9 thq-flex-column">
                  <strong class="products-title32 thq-heading-3">
                    Mezzanine Finance
                  </strong>
                  <span class="products-description32 thq-body-small">
                    <span>
                      A hybrid funding solution available to both originators and
                      intermediaries. Benefit from flexible capital that bridges
                      traditional debt and equity for your growth projects.
                    </span>
                    <br />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="products-container2">
            <router-link
              to="/contact"
              class="products-navlink1 button button-gradient"
            >
              Get a Quote
            </router-link>
            <router-link
              to="/book-a-call"
              class="products-navlink2 button button-transparent"
            >
              Book a call
            </router-link>
          </div>
          <span class="products-link13 thq-body-small">
            Loan products are subject to credit approval. Failure to maintain
            repayments may result in repossession of the secured asset. Terms and
            conditions apply.
          </span>
        </div>
      </div>
    </div>
    <div class="products-section2 section-container">
      <div class="products-max-width4 max-width">
        <div class="products-content10">
          <span class="products-text22">our track record</span>
          <h1 class="products-text23">
            <span class="products-text24">Over </span>
            <span class="products-text25">
              Two Decades
              <span v-html="rawvgww"></span>
            </span>
            <span class="products-text26">of Combined Experience</span>
          </h1>
          <div class="products-cards thq-section-padding">
            <div
              class="products-max-width5 thq-grid-auto-300 thq-section-max-width"
            >
              <div class="products-container3 thq-flex-column thq-card">
                <h2 class="products-text27 thq-heading-2">£100 million+</h2>
                <span class="products-text28 thq-body-small">
                  Capital Deployed
                </span>
              </div>
              <div class="products-container4 thq-flex-column thq-card">
                <h2 class="products-text29 thq-heading-2">100</h2>
                <span class="products-text30 thq-body-small">
                  Completed Deals
                </span>
              </div>
              <div class="products-container5 thq-flex-column thq-card">
                <h2 class="products-text31 thq-heading-2">9.2%</h2>
                <span class="products-text32 thq-body-small">
                  Avg. Gross Interest Rate p.a
                </span>
              </div>
              <div class="products-container6 thq-flex-column thq-card">
                <h2 class="products-text33 thq-heading-2">40+ Years</h2>
                <span class="products-text34 thq-body-small">
                  of Wealth Management Experience
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="products-section3 section-container">
      <div class="products-max-width6 max-width">
        <div class="products-content11">
          <span class="beforeHeading products-text35">
            Clear, transparent terms
          </span>
          <h1 class="products-text36">
            <span>Our </span>
            <span class="products-text38">Criteria</span>
          </h1>
          <ul class="products-ul list">
            <li class="products-li1 list-item">
              <span class="products-text39">
                Financing of up to £1 million per deal
              </span>
            </li>
            <li class="products-li2 list-item">
              <span class="products-text40">
                Interest Rates starting at 0.80% per month
              </span>
            </li>
            <li class="products-li3 list-item">
              <span class="products-text41">Loan to Values up to 75% </span>
            </li>
            <li class="products-li4 list-item">
              <span>
                <span class="products-text43">Flexible Terms</span>
                <br />
              </span>
            </li>
            <li class="products-li5 list-item">
              <span class="products-text45">No exit fees</span>
            </li>
          </ul>
          <a
            href="https://go.plotpoint.co.uk/ppproductguide"
            target="_blank"
            rel="noreferrer noopener"
            class="products-link1 button button-transparent"
          >
            Download our Product Guide
          </a>
        </div>
        <div class="products-image3">
          <img
            alt="image"
            src="/products/criteria-1200w.jpg"
            class="products-hero-image2"
          />
        </div>
      </div>
    </div>
    <div class="products-section4 section-container">
      <div class="products-max-width7 max-width">
        <div class="products-image4">
          <img
            alt="image"
            src="/partnerwithus/originator1-1200w.jpg"
            class="products-hero-image3"
          />
        </div>
        <div class="products-content12">
          <span class="subtitle-top">EFFORTLESS, QUICK DECISIONS</span>
          <h1 class="products-text47">Apply in Three Simple steps</h1>
          <div class="products-step1">
            <div class="products-number1">
              <span class="products-text48">1</span>
            </div>
            <div class="products-container7">
              <span class="steps-heading">Consult with Our Team</span>
              <span class="products-text49">
                Speak with one of our dedicated experts who will assess your needs
                and guide you through the process.
              </span>
            </div>
          </div>
          <div class="products-step2">
            <div class="products-number2">
              <span class="products-text50">2</span>
            </div>
            <div class="products-container8">
              <span class="products-title6 steps-heading">
                Complete a Simple Application
              </span>
              <span class="products-text51">
                <span>
                  Fill out our streamlined form with essential details for a fast,
                  tailored review.
                </span>
                <br />
              </span>
            </div>
          </div>
          <div class="products-step3">
            <div class="products-number3">
              <span class="products-text54">3</span>
            </div>
            <div class="products-container9">
              <span class="products-title7 steps-heading">
                Receive a Swift Decision
              </span>
              <span class="products-text55">
                Once you&apos;ve selected a deal, you transfer your funds to a
                solicitor client account, and you can monitor your deal&apos;s
                performance and project updates through our dashboard.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <case-study rootClassName="case-studyroot-class-name">
      <template v-slot:text>
        <div class="products-fragment10">
          <span class="products-text56">FEATURED PROJECT</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="products-fragment11">
          <span class="products-text57">
            Partnering with the borrower, we recently finalized a £600,000
            three-tranche, asset-backed bridge and development loan in Shoreditch,
            London.
          </span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="products-fragment12">
          <span class="products-text58">Gross Interest Rate p.a</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="products-fragment13">
          <span class="products-text59">Loan Term</span>
        </div>
      </template>
      <template v-slot:text4>
        <div class="products-fragment14">
          <span class="products-text60">Loan to Value (LTV)</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="products-fragment15">
          <span class="products-text61">Secured Asset-Backed Lending</span>
        </div>
      </template>
      <template v-slot:heading>
        <div class="products-fragment16">
          <span class="products-text62">
            <span>
              Bridge Loan in
              <span v-html="raw5dk4"></span>
            </span>
            <span class="products-text64">Shoreditch</span>
          </span>
        </div>
      </template>
      <template v-slot:heading1>
        <div class="products-fragment17">
          <span class="products-text65">10%</span>
        </div>
      </template>
      <template v-slot:heading2>
        <div class="products-fragment18">
          <span class="products-text66">11 Months</span>
        </div>
      </template>
      <template v-slot:heading3>
        <div class="products-fragment19">
          <span class="products-text67">46%</span>
        </div>
      </template>
      <template v-slot:heading4>
        <div class="products-fragment20">
          <span class="products-text68">First Charge and Debenture</span>
        </div>
      </template>
    </case-study>
    <div class="products-section5 section-container">
      <div class="products-max-width8 max-width">
        <div class="products-banner">
          <span class="products-text69 beforeHeading">
             Expert guidance at every step
          </span>
          <h1 class="products-text70">Speak to your Dedicated Manager</h1>
          <span class="products-text71">
            Connect with your personal account manager today for tailored advice
            and support. Our expert team is here to answer your questions, guide
            you through our products, and ensure your project receives the
            attention it deserves.
          </span>
          <img
            alt="image"
            src="/common/group%2018-1200w.png"
            class="products-image5"
          />
          <img
            alt="image"
            src="/common/group%2018-1200w.png"
            class="products-image6"
          />
          <div class="products-btns">
            <router-link
              to="/contact"
              class="products-navlink3 button button-transparent"
            >
              <span class="products-text72">Get a Quote</span>
            </router-link>
            <router-link
              to="/book-a-call"
              class="products-navlink4 button button-gradient"
            >
              <span class="products-text73">Book a call</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <app-footer>
      <template v-slot:text>
        <div class="products-fragment21">
          <span class="products-text74">About Us</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="products-fragment22">
          <span class="products-text75">About us</span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="products-fragment23">
          <span class="products-text76">Private Credit</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="products-fragment24">
          <span class="products-text77">Partner with Us</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="products-fragment25">
          <span class="products-text78">Contact</span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="products-fragment26">
          <span class="products-text79">App</span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="products-fragment27">
          <span class="products-text80">Download iOS app</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="products-fragment28">
          <span class="products-text81">Download Android app</span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="products-fragment29">
          <span class="products-text82">Originator Portal</span>
        </div>
      </template>
      <template v-slot:link13>
        <div class="products-fragment30">
          <span class="products-text83">Your Capital is at Risk</span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="products-fragment31">
          <span class="products-text84">Resources</span>
        </div>
      </template>
      <template v-slot:text11>
        <div class="products-fragment32">
          <span class="products-text85">Funder Terms and Conditions</span>
        </div>
      </template>
      <template v-slot:text13>
        <div class="products-fragment33">
          <span class="products-text86">Disclaimer</span>
        </div>
      </template>
      <template v-slot:text14>
        <div class="products-fragment34">
          <span class="products-text87">Privacy Policy</span>
        </div>
      </template>
      <template v-slot:text15>
        <div class="products-fragment35">
          <span class="products-text88">Products</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="products-fragment36">
          <span class="products-content1">
            <span>Fourth Floor, 70 Baker Street, </span>
            <span>London, W1U 7DL</span>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content2>
        <div class="products-fragment37">
          <span class="products-content2">
            <span class="products-text92">Phone: </span>
            <a href="tel:+442081547035" class="products-link2">+</a>
            <a href="tel:+442081547035" class="products-link3">44 208 154 7035</a>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content3>
        <div class="products-fragment38">
          <span class="products-content3">
            <span class="products-text94">
              ©2024 PlotPoint Limited. All rights reserved.
            </span>
            <br />
            <span>
              Registered in England &amp; Wales (Reg Number: 15914598).
              <span v-html="rawenbr"></span>
            </span>
          </span>
        </div>
      </template>
      <template v-slot:contentTitle1>
        <div class="products-fragment39">
          <span class="products-text97">PlotPoint Limited</span>
        </div>
      </template>
    </app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import CaseStudy from '../components/case-study'
import AppFooter from '../components/footer'

export default {
  name: 'Products',
  props: {},
  components: {
    AppHeader,
    CaseStudy,
    AppFooter,
  },
  data() {
    return {
      rawvgww: ' ',
      raw5dk4: ' ',
      rawenbr: ' ',
    }
  },
  metaInfo: {
    title: 'Bridging and Development Financing - PlotPoint',
    meta: [
      {
        name: 'description',
        content:
          "Explore PlotPoint's flexible funding solutions—bridging, refurbishment, development, and mezzanine loans—with quick decisions and expert support.",
      },
      {
        property: 'og:title',
        content: 'Bridging and Development Financing - PlotPoint',
      },
      {
        property: 'og:description',
        content:
          "Explore PlotPoint's flexible funding solutions—bridging, refurbishment, development, and mezzanine loans—with quick decisions and expert support.",
      },
    ],
  },
}
</script>

<style scoped>
.products-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.products-hero {
  padding-top: 0px;
  padding-bottom: 0px;
}
 
.products-max-width1 {
  align-items: flex-start;
  padding-top: 0px;
  padding-bottom: 0px;
}
 
.products-content4 {
  flex: 0 0 auto;
  width: 45%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
 
.products-subtitle {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  letter-spacing: 0.12em;
  text-transform: uppercase;
}
 
.products-title4 {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1;
  margin-bottom: var(--dl-space-space-unit);
}
 
.products-text11 {
  color: var(--dl-color-scheme-celadon);
}
 
.products-description {
  color: var(--dl-color-scheme-white);
  font-size: 18px;
  font-family: "Bricolage Grotesque";
  line-height: 1.5;
  padding-bottom: var(--dl-space-space-unit);
}
 
.products-image1 {
  padding: 0px;
  position: relative;
}
 
.products-hero-image1 {
  flex: 1;
  height: auto;
  max-width: 550px;
  object-fit: cover;
  border-radius: 48px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
 
.products-image2 {
  top: -1px;
  right: -4px;
  position: absolute;
  object-fit: contain;
}
 
.products-section1 {
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
 
.products-max-width2 {
  align-items: stretch;
}
 
.products-content5 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  margin-left: 0px;
  flex-direction: column;
  justify-content: center;
}
 
.products-text15 {
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-unit);
}
 
.products-text17 {
  color: var(--dl-color-scheme-medgreen);
}
 
.products-layout226 {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
 
.products-max-width3 {
  align-self: flex-start;
}
 
.products-content6 {
  gap: 0;
}
 
.products-title1 {
  text-align: center;
}
 
.products-description1 {
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.products-content7 {
  gap: 0;
}
 
.products-title2 {
  text-align: center;
}
 
.products-description2 {
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.products-content8 {
  gap: 0;
}
 
.products-title31 {
  text-align: center;
}
 
.products-description31 {
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.products-content9 {
  gap: 0;
}
 
.products-title32 {
  text-align: center;
}
 
.products-description32 {
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.products-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: stretch;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: center;
}
 
.products-navlink1 {
  transition: 0.3s;
  text-decoration: none;
}
 
.products-navlink1:hover {
  fill: var(--dl-color-scheme-green100);
  color: var(--dl-color-scheme-green100);
  border-color: var(--dl-color-scheme-green100);
}
 
.products-navlink2 {
  fill: var(--dl-color-scheme-green80);
  color: var(--dl-color-scheme-green80);
  border-color: var(--dl-color-scheme-green80);
  text-decoration: none;
}
 
.products-link13 {
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
}
 
.products-section2 {
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: var(--dl-color-scheme-green100);
}
 
.products-max-width4 {
  align-items: stretch;
}
 
.products-content10 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
 
.products-text22 {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
 
.products-text23 {
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-unit);
}
 
.products-text24 {
  color: var(--dl-color-scheme-white);
}
 
.products-text25 {
  color: var(--dl-color-scheme-celadon);
}
 
.products-text26 {
  color: var(--dl-color-scheme-white);
}
 
.products-cards {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
 
.products-max-width5 {
  display: flex;
  align-items: center;
}
 
.products-container3 {
  gap: 0;
  color: var(--dl-color-theme-neutral-light);
  width: 25%;
  height: var(--dl-size-size-large);
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.products-text27 {
  color: var(--dl-color-scheme-green80);
  height: 55px;
  font-size: 32px;
  text-align: center;
}
 
.products-text28 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 12px;
  font-family: "Bricolage Grotesque";
}
 
.products-container4 {
  gap: 0;
  color: var(--dl-color-theme-neutral-light);
  width: 25%;
  height: var(--dl-size-size-large);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.products-text29 {
  color: var(--dl-color-scheme-green80);
  height: 55px;
}
 
.products-text30 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 12px;
  font-family: "Bricolage Grotesque";
}
 
.products-container5 {
  gap: 0;
  color: var(--dl-color-theme-neutral-light);
  width: 25%;
  height: var(--dl-size-size-large);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.products-text31 {
  color: var(--dl-color-scheme-green80);
}
 
.products-text32 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 12px;
  font-family: "Bricolage Grotesque";
}
 
.products-container6 {
  gap: 0;
  color: var(--dl-color-theme-neutral-light);
  width: 25%;
  height: var(--dl-size-size-large);
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.products-text33 {
  color: var(--dl-color-scheme-green80);
}
 
.products-text34 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 12px;
  font-family: "Bricolage Grotesque";
}
 
.products-section3 {
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: var(--dl-color-scheme-honeydew);
}
 
.products-max-width6 {
  align-items: stretch;
}
 
.products-content11 {
  width: 60%;
  display: flex;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
 
.products-text36 {
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-unit);
}
 
.products-text38 {
  color: var(--dl-color-scheme-medgreen);
}
 
.products-ul {
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: 0px;
  padding-bottom: 0px;
  list-style-type: square;
}
 
.products-li1 {
  padding-bottom: var(--dl-space-space-unit);
}
 
.products-text39 {
  font-weight: bold;
}
 
.products-li2 {
  margin-bottom: var(--dl-space-space-unit);
}
 
.products-text40 {
  font-weight: bold;
}
 
.products-li3 {
  margin-bottom: var(--dl-space-space-unit);
}
 
.products-text41 {
  font-weight: bold;
}
 
.products-li4 {
  margin-bottom: var(--dl-space-space-unit);
}
 
.products-text43 {
  font-weight: 700;
}
 
.products-li5 {
  margin-bottom: var(--dl-space-space-unit);
}
 
.products-text45 {
  font-weight: bold;
}
 
.products-link1 {
  fill: var(--dl-color-scheme-green80);
  color: var(--dl-color-scheme-green80);
  width: auto;
  align-self: flex-start;
  text-align: center;
  border-color: var(--dl-color-scheme-green80);
  text-decoration: none;
}
 
.products-hero-image2 {
  width: 600px;
  height: 400px;
  object-fit: cover;
  border-radius: 48px;
}
 
.products-section4 {
  padding-top: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: var(--dl-color-scheme-green80);
}
 
.products-max-width7 {
  align-items: stretch;
}
 
.products-image4 {
  width: 50%;
  align-self: center;
}
 
.products-hero-image3 {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 48px;
}
 
.products-content12 {
  width: 40%;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  flex-direction: column;
  justify-content: center;
}
 
.products-text47 {
  color: var(--dl-color-scheme-white);
}
 
.products-step1 {
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
 
.products-number1 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.products-text48 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
 
.products-container7 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.products-text49 {
  color: var(--dl-color-scheme-white);
}
 
.products-step2 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
 
.products-number2 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.products-text50 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
 
.products-container8 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.products-title6 {
  color: var(--dl-color-scheme-white);
}
 
.products-text51 {
  color: var(--dl-color-scheme-white);
}
 
.products-step3 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
 
.products-number3 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.products-text54 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
 
.products-container9 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.products-title7 {
  color: var(--dl-color-scheme-white);
}
 
.products-text55 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
 
.products-fragment10 {
  display: contents;
}
 
.products-text56 {
  display: inline-block;
}
 
.products-fragment11 {
  display: contents;
}
 
.products-text57 {
  display: inline-block;
}
 
.products-fragment12 {
  display: contents;
}
 
.products-text58 {
  display: inline-block;
}
 
.products-fragment13 {
  display: contents;
}
 
.products-text59 {
  display: inline-block;
}
 
.products-fragment14 {
  display: contents;
}
 
.products-text60 {
  display: inline-block;
}
 
.products-fragment15 {
  display: contents;
}
 
.products-text61 {
  display: inline-block;
}
 
.products-fragment16 {
  display: contents;
}
 
.products-text62 {
  display: inline-block;
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
}
 
.products-text64 {
  color: var(--dl-color-scheme-white);
}
 
.products-fragment17 {
  display: contents;
}
 
.products-text65 {
  display: inline-block;
}
 
.products-fragment18 {
  display: contents;
}
 
.products-text66 {
  display: inline-block;
}
 
.products-fragment19 {
  display: contents;
}
 
.products-text67 {
  display: inline-block;
}
 
.products-fragment20 {
  display: contents;
}
 
.products-text68 {
  display: inline-block;
}
 
.products-section5 {
  position: relative;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
 
.products-max-width8 {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.products-banner {
  clip: auto;
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 48px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: var(--dl-color-scheme-green100);
}
 
.products-text69 {
  color: var(--dl-color-scheme-honeydew);
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.products-text70 {
  color: var(--dl-color-scheme-white);
  z-index: 100;
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: var(--dl-space-space-halfunit);
}
 
.products-text71 {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  width: auto;
  z-index: 100;
  text-align: center;
  padding-left: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: 0px;
}
 
.products-image5 {
  top: 35px;
  right: 3px;
  width: 195px;
  height: 275px;
  opacity: 0.4;
  z-index: 1;
  position: absolute;
  object-fit: contain;
}
 
.products-image6 {
  top: 19px;
  left: 2px;
  width: 195px;
  height: 275px;
  opacity: 0.4;
  z-index: 1;
  position: absolute;
  transform: rotate(180deg);
  object-fit: contain;
}
 
.products-btns {
  flex: 0 0 auto;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
 
.products-navlink3 {
  font-size: 16px;
  text-decoration: none;
}
 
.products-navlink4 {
  text-align: center;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
 
.products-fragment21 {
  display: contents;
}
 
.products-text74 {
  display: inline-block;
}
 
.products-fragment22 {
  display: contents;
}
 
.products-text75 {
  display: inline-block;
}
 
.products-fragment23 {
  display: contents;
}
 
.products-text76 {
  display: inline-block;
}
 
.products-fragment24 {
  display: contents;
}
 
.products-text77 {
  display: inline-block;
}
 
.products-fragment25 {
  display: contents;
}
 
.products-text78 {
  display: inline-block;
}
 
.products-fragment26 {
  display: contents;
}
 
.products-text79 {
  display: inline-block;
}
 
.products-fragment27 {
  display: contents;
}
 
.products-text80 {
  display: inline-block;
}
 
.products-fragment28 {
  display: contents;
}
 
.products-text81 {
  display: inline-block;
}
 
.products-fragment29 {
  display: contents;
}
 
.products-text82 {
  display: inline-block;
}
 
.products-fragment30 {
  display: contents;
}
 
.products-text83 {
  display: inline-block;
}
 
.products-fragment31 {
  display: contents;
}
 
.products-text84 {
  display: inline-block;
}
 
.products-fragment32 {
  display: contents;
}
 
.products-text85 {
  display: inline-block;
}
 
.products-fragment33 {
  display: contents;
}
 
.products-text86 {
  display: inline-block;
}
 
.products-fragment34 {
  display: contents;
}
 
.products-text87 {
  display: inline-block;
}
 
.products-fragment35 {
  display: contents;
}
 
.products-text88 {
  display: inline-block;
}
 
.products-fragment36 {
  display: contents;
}
 
.products-content1 {
  display: inline-block;
}
 
.products-fragment37 {
  display: contents;
}
 
.products-content2 {
  display: inline-block;
}
 
.products-text92 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
 
.products-link2 {
  text-decoration: underline;
}
 
.products-link3 {
  text-decoration: underline;
}
 
.products-fragment38 {
  display: contents;
}
 
.products-content3 {
  display: inline-block;
}
 
.products-text94 {
  font-weight: 700;
}
 
.products-fragment39 {
  display: contents;
}
 
.products-text97 {
  display: inline-block;
}
 
@media(max-width: 1200px) {
  .products-text11 {
    color: var(--dl-color-scheme-celadon);
  }
  .products-text17 {
    color: var(--dl-color-scheme-celadon);
  }
  .products-text25 {
    color: var(--dl-color-scheme-celadon);
  }
  .products-text26 {
    color: var(--dl-color-scheme-celadon);
  }
  .products-text36 {
    font-family: Bricolage Grotesque;
  }
  .products-text38 {
    color: var(--dl-color-scheme-medgreen);
  }
  .products-text62 {
    font-size: 42px;
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 700;
    line-height: 1.25;
  }
}
 
@media(max-width: 991px) {
  .products-content4 {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }
  .products-image1 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .products-hero-image1 {
    margin-bottom: 0px;
  }
  .products-max-width2 {
    flex-direction: column;
  }
  .products-content5 {
    width: 100%;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  .products-text14 {
    text-align: center;
  }
  .products-text15 {
    text-align: center;
  }
  .products-content6 {
    align-items: center;
  }
  .products-content7 {
    align-items: center;
  }
  .products-content8 {
    align-items: center;
  }
  .products-content9 {
    align-items: center;
  }
  .products-max-width4 {
    flex-direction: column;
  }
  .products-content10 {
    width: 100%;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  .products-text22 {
    text-align: center;
  }
  .products-text23 {
    text-align: center;
  }
  .products-max-width5 {
    gap: var(--dl-space-space-twounits);
    flex-direction: row;
  }
  .products-container3 {
    height: var(--dl-size-size-medium);
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .products-text27 {
    height: auto;
    font-size: 24px;
  }
  .products-container4 {
    height: var(--dl-size-size-medium);
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .products-text29 {
    height: auto;
    font-size: 24px;
  }
  .products-container5 {
    height: var(--dl-size-size-medium);
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .products-text31 {
    font-size: 24px;
  }
  .products-text32 {
    font-size: 10px;
  }
  .products-container6 {
    height: var(--dl-size-size-medium);
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .products-text33 {
    font-size: 24px;
  }
  .products-text34 {
    text-align: center;
  }
  .products-section3 {
    align-items: center;
  }
  .products-max-width6 {
    flex-direction: column;
  }
  .products-content11 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .products-text35 {
    width: auto;
    align-self: center;
  }
  .products-text36 {
    text-align: center;
  }
  .products-ul {
    width: auto;
    align-self: center;
  }
  .products-link1 {
    align-self: center;
  }
  .products-image3 {
    padding-bottom: 0px;
  }
  .products-hero-image2 {
    width: 100%;
    height: 300px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .products-max-width7 {
    flex-direction: column;
  }
  .products-image4 {
    width: 100%;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .products-hero-image3 {
    width: 100%;
    height: 300px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .products-content12 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .products-step1 {
    justify-content: center;
  }
  .products-text55 {
    color: var(--dl-color-scheme-white);
    line-height: 1.5;
  }
  .products-max-width8 {
    flex-direction: column;
  }
}
 
@media(max-width: 767px) {
  .products-hero {
    flex-direction: column;
  }
  .products-max-width1 {
    gap: 0;
    flex-direction: column;
  }
  .products-content4 {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
    margin-right: 0px;
  }
  .products-image1 {
    width: 100%;
  }
  .products-hero-image1 {
    width: 100%;
    max-width: 100%;
    max-height: 300px;
    margin-right: 0px;
    padding-right: 0px;
    border-top-left-radius: 48px;
    border-top-right-radius: 48px;
  }
  .products-image2 {
    top: 66px;
    right: -137px;
    display: none;
  }
  .products-content5 {
    width: 100%;
    margin-right: 0px;
  }
  .products-text15 {
    font-family: Bricolage Grotesque;
  }
  .products-text17 {
    color: var(--dl-color-scheme-celadon);
  }
  .products-title1 {
    text-align: center;
  }
  .products-link13 {
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 700;
  }
  .products-content10 {
    width: 100%;
    margin-right: 0px;
  }
  .products-text23 {
    font-family: Bricolage Grotesque;
  }
  .products-text25 {
    color: var(--dl-color-scheme-celadon);
  }
  .products-text26 {
    color: var(--dl-color-scheme-celadon);
  }
  .products-max-width5 {
    flex-direction: column;
  }
  .products-container3 {
    width: 100%;
  }
  .products-container4 {
    width: 100%;
  }
  .products-container5 {
    width: 100%;
  }
  .products-container6 {
    width: 100%;
  }
  .products-content11 {
    width: 100%;
    margin-right: 0px;
  }
  .products-hero-image2 {
    height: var(--dl-size-size-xxlarge);
  }
  .products-content12 {
    width: 100%;
    margin-right: 0px;
  }
  .products-banner {
    padding: var(--dl-space-space-twounits);
  }
  .products-image5 {
    top: 40px;
    right: 1px;
  }
  .products-image6 {
    top: 2px;
    right: -166px;
  }
  .products-navlink4 {
    z-index: 100;
  }
}
 
@media(max-width: 479px) {
  .products-title1 {
    text-align: center;
  }
  .products-text27 {
    color: var(--dl-color-scheme-green80);
    font-size: 24px;
  }
  .products-hero-image2 {
    height: var(--dl-size-size-large);
  }
  .products-image4 {
    padding-bottom: 0px;
  }
  .products-hero-image3 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .products-text62 {
    font-family: Bricolage Grotesque;
  }
  .products-text64 {
    color: var(--dl-color-scheme-white);
  }
  .products-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .products-text71 {
    line-height: 1.4;
  }
  .products-image5 {
    top: 143px;
    right: 1px;
  }
  .products-btns {
    gap: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .products-navlink3 {
    z-index: 100;
    text-align: center;
    font-family: Bricolage Grotesque;
  }
  .products-text72 {
    font-size: 16px;
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 700;
  }
  .products-navlink4 {
    z-index: 100;
    font-size: 16px;
    font-family: Bricolage Grotesque;
  }
  .products-text73 {
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 600;
  }
}
</style>
