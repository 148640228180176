<template>
  <div class="contact-form-container1" v-bind:class="rootClassName">
    <form class="contact-form-form thq-flex-column" @submit.prevent="submitForm"  v-if="formStatus != 'success'">
      <div class="contact-form-container2">
        <div class="contact-form-input1">
          <label
            for="contact-form-6-first-name"
            class="contact-form-text1 thq-body-small"
          >
            First name
          </label>
          <input
            type="text"
            id="contact-form-6-first-name"
            v-model="firstName" 
            required 
            placeholder="Enter your first name"
            class="contact-form-text-input1 thq-input"
			autocomplete="given-name"
          />
        </div>
        <div class="contact-form-input2">
          <label
            for="contact-form-6-last-name"
            class="contact-form-text2 thq-body-small"
          >
            Last name
          </label>
          <input
            type="text"
            id="contact-form-6-last-name"
            v-model="lastName" 
            required
            placeholder="Enter your last name"
            class="contact-form-text-input2 thq-input"
			autocomplete="family-name"
          />
        </div>
      </div>
      <div class="contact-form-container3">
        <div class="contact-form-input3">
          <label
            for="contact-form-6-email"
            class="contact-form-text3 thq-body-small"
          >
            Email
          </label>
          <input
            type="email"
            id="contact-form-6-email"
            v-model="email" 
            required
            placeholder="Enter your email"
            class="contact-form-text-input3 thq-input"
			autocomplete="email"
          />
        </div>
        <div class="contact-form-input4">
          <label
            for="contact-form-6-phone"
            class="contact-form-text4 thq-body-small"
          >
            Phone Number
          </label>
          <input
            type="tel"
            id="contact-form-6-phone"
            required="true"
            placeholder="Phone Number"
            class="contact-form-text-input4 thq-input"
			v-model="phone" 
			autocomplete="tel"
          />
        </div>
      </div>
      <div class="contact-form-container4">
        <div class="contact-form-input5">
          <label
            for="contact-form-6-options"
            class="contact-form-text5 thq-body-small"
          >
            Please select your topic
          </label>
          <select
            id="contact-form-6-options"
            required="true"
            placeholder="Select one"
            class="contact-form-select thq-select"
			v-model="topic" 
          >
            <option value="Interested in a Bridge Loan">
              Interested in a Bridge Loan
            </option>
            <option value="Interested in a Bridge Loan">
              Interested in a Refurbishment Loan
            </option>
            <option value="Interested in Development Financing">
              Interested in Development Financing
            </option>
            <option value="Interested in a Mezzanine Loan">
              Interested in a Mezzanine Loan
            </option>
            <option
              value="Interested in Partnering up with PlotPoint (Originators)"
            >
              Interested in Partnering up with PlotPoint (Originators)
            </option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>
      <div class="contact-form-container5">
        <div class="contact-form-input6">
          <label
            for="contact-form-6-message"
            class="contact-form-text6 thq-body-small"
          >
            Message
          </label>
          <textarea
            id="contact-form-6-message"
            rows="5"
            placeholder="Enter your message"
			required 
			v-model="message" 
            class="contact-form-textarea thq-input"
          ></textarea>
        </div>
      </div>
      <div class="contact-form-checkbox1">
        <input
          type="checkbox"
          id="contact-form-6-check"
          v-model="acceptedTerms" 
          required

          class="thq-checkbox"
        />
        <router-link to="/privacy-policy" class="contact-form-navlink">
          <label
            for="contact-form-6-check"
            class="contact-form-text7 thq-body-small"
          >
            I accept our Terms and Conditions & Privacy Policy
          </label>
        </router-link>
      </div>
	  <!-- Turnstile CAPTCHA widget -->
      <TurnstileCaptcha ref="captcha" />


      <button type="submit" class="contact-form-button thq-button-filled">
        <span class="contact-form-action1 thq-body-small">Submit</span>
      </button>
    </form>

    <div v-if="formStatus === 'success'" class="contact-form-text2">
      Thank you for your message! Someone from our team will get back to you soon.
    </div>
    <div v-if="formStatus === 'error'" class="contact-form-text2">
      There was an error submitting the form. Please refresh the page and try again.
    </div>

  </div>
</template>

<script>
// Import the TurnstileCaptcha component
import TurnstileCaptcha from './TurnstileCaptcha.vue';

export default {
  name: 'ContactForm',
  props: {
    rootClassName: String,
  },
  components: {
    TurnstileCaptcha,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      phone: '',
      topic: '',
      acceptedTerms: false,
      formStatus: '', // Tracks form status (success, error)
    };
  },
  methods: {
    async submitForm() {
      // Perform client-side validation
      if (!this.firstName || !this.lastName || !this.email || !this.message || !this.phone || !this.topic || !this.acceptedTerms) {
        alert('Please fill out all fields and accept the terms.');
        return;
      }

      // Get Turnstile token from the TurnstileCaptcha component
      const turnstileToken = this.$refs.captcha.turnstileToken;

      if (!turnstileToken) {
        alert('CAPTCHA validation failed. Please try again.');
        return;
      }

      try {
        // Prepare form data
        const formData = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          message: this.message,
          phone: this.phone,
          topic: this.topic,
          turnstileToken, // Include the CAPTCHA token
        };

        // Submit the form to Cloudflare Worker
        const response = await fetch('https://cersei.plotpoint.co.uk/webform', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData),
        });

        const result = await response.json();
        console.log('Response from server:', result); // Log response for debugging

        if (result.success) {
          this.formStatus = 'success';
        } else {
          this.formStatus = 'error';
        }
      } catch (error) {
        this.formStatus = 'error';
        console.error('Form submission error:', error); // Log error for debugging
      }
    },
  },
};
</script>


<style scoped>
.thq-input {padding:1.2rem;}

.contact-form-container1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.contact-form-form {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  align-items: flex-start;
}
 
.contact-form-container2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.contact-form-input1 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-text1 {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  font-family: "Bricolage Grotesque";
}
 
.contact-form-text-input1 {
  fill: var(--dl-color-scheme-green100);
  color: var(--dl-color-scheme-green100);
  background-color: var(--dl-color-scheme-honeydew);
}
 
.contact-form-input2 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-text2 {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  font-family: "Bricolage Grotesque";
}
 
.contact-form-text-input2 {
  fill: var(--dl-color-scheme-green100);
  color: var(--dl-color-scheme-green100);
  background-color: var(--dl-color-scheme-honeydew);
}
 
.contact-form-container3 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.contact-form-input3 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-text3 {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  font-family: "Bricolage Grotesque";
}
 
.contact-form-text-input3 {
  fill: var(--dl-color-scheme-green100);
  color: var(--dl-color-scheme-green100);
  text-align: left;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.contact-form-input4 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-text4 {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  font-family: "Bricolage Grotesque";
}
 
.contact-form-text-input4 {
  fill: var(--dl-color-scheme-green100);
  color: var(--dl-color-scheme-green100);
  background-color: var(--dl-color-scheme-honeydew);
}
 
.contact-form-container4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-input5 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-text5 {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  font-family: "Bricolage Grotesque";
}
 
.contact-form-select {
  width: 100%;
  margin-right: 0px;
  padding:1.2rem;
}
 
.contact-form-container5 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-input6 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
 
.contact-form-text6 {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  font-family: "Bricolage Grotesque";
}
 
.contact-form-textarea {
  fill: var(--dl-color-scheme-green100);
  color: var(--dl-color-scheme-green100);
  background-color: var(--dl-color-scheme-honeydew);
}
 
.contact-form-checkbox1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
}
 
.contact-form-navlink {
  display: contents;
}
 
.contact-form-text7 {
  color: var(--dl-color-scheme-white);
  font-family: "Bricolage Grotesque";
  text-decoration: none;
}
 
.contact-form-button {
  align-self: flex-start;
  transition: 0.3s;
}
 

 
.contact-form-action1 {
  font-family: "Bricolage Grotesque";
}

 
.contact-formroot-class-name {
  width: 50%;
}
 

@media(max-width: 991px) {
  .contact-form-form {
    width: 100%;
  }
  .contact-form-container2 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form-input1 {
    width: 50%;
  }
  .contact-form-input2 {
    width: 50%;
    flex-direction: column;
  }
  .contact-form-container3 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form-input3 {
    width: 50%;
  }
  .contact-form-input4 {
    width: 50%;
  }
  .contact-form-container4 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form-input5 {
    gap: var(--dl-space-space-halfunit);
    width: 50%;
    flex-direction: column;
  }
  .contact-form-container5 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form-checkbox1 {
    align-self: flex-start;
  }
  .contact-formroot-class-name {
    width: 100%;
  }
}
 
@media(max-width: 767px) {
  .contact-form-container2 {
    flex-direction: column;
  }
  .contact-form-input1 {
    width: 100%;
  }
  .contact-form-input2 {
    width: 100%;
  }
  .contact-form-container3 {
    flex-direction: column;
  }
  .contact-form-input3 {
    width: 100%;
  }
  .contact-form-input4 {
    width: 100%;
  }
  .contact-form-input5 {
    width: 100%;
  }
  .contact-form-text7 {
    color: var(--dl-color-scheme-white);
    font-family: Bricolage Grotesque;
    text-decoration: underline;
  }
}
 
@media(max-width: 479px) {
  .contact-form-form {
    padding: var(--dl-space-space-unit);
  }
  .contact-form-container2 {
    align-items: stretch;
    flex-direction: column;
  }
  .contact-form-input1 {
    width: 100%;
  }
  .contact-form-input2 {
    width: 100%;
  }
  .contact-form-container3 {
    align-items: stretch;
    flex-direction: column;
  }
  .contact-form-input3 {
    width: 100%;
  }
  .contact-form-input4 {
    width: 100%;
  }
}
</style>
