<template>
  <div class="case-study-section section-container" v-bind:class="rootClassName">
    <div class="case-study-container10 thq-section-padding">
      <div class="case-study-max-width thq-section-max-width">
        <div class="case-study-container11 thq-flex-column">
          <div class="case-study-container12">
            <span class="beforeHeading">
              <slot name="text">
                <!--Default content for text-->
                <div class="case-study-fragment10">
                  <span class="case-study-text21">FEATURED PROJECT</span>
                </div>
              </slot>
            </span>
            <h1 class="case-study-text11">
              <slot name="heading">
                <!--Default content for heading-->
                <div class="case-study-fragment17">
                  <h1 class="case-study-text28">
                    <span>
                      Bridge Loan in
                      <span v-html="rawfmu7"></span>
                    </span>
                    <span class="case-study-text30">Shoreditch</span>
                  </h1>
                </div>
              </slot>
            </h1>
            <p class="thq-body-large">
              <slot name="text1">
                <!--Default content for text1-->
                <div class="case-study-fragment19">
                  <span class="case-study-text32">
                    Partnering with the borrower, we recently finalized a £600,000
                    three-tranche, asset-backed bridge and development loan in
                    Shoreditch, London.
                  </span>
                </div>
              </slot>
            </p>
          </div>
          <div class="case-study-container13">
            <div class="case-study-container14">
              <h2 class="case-study-text13 thq-heading-2">
                <slot name="heading1">
                  <!--Default content for heading1-->
                  <div class="case-study-fragment14">
                    <span class="case-study-text25">10%</span>
                  </div>
                </slot>
              </h2>
              <span class="case-study-text14 thq-body-small">
                <slot name="text2">
                  <!--Default content for text2-->
                  <div class="case-study-fragment20">
                    <span class="case-study-text33">Gross Interest Rate p.a</span>
                  </div>
                </slot>
              </span>
            </div>
            <div class="case-study-container15">
              <h2 class="case-study-text15 thq-heading-2">
                <slot name="heading2">
                  <!--Default content for heading2-->
                  <div class="case-study-fragment16">
                    <span class="case-study-text27">11 Months</span>
                  </div>
                </slot>
              </h2>
              <span class="case-study-text16 thq-body-small">
                <slot name="text3">
                  <!--Default content for text3-->
                  <div class="case-study-fragment12">
                    <span class="case-study-text23">Loan Term</span>
                  </div>
                </slot>
              </span>
            </div>
          </div>
          <div class="case-study-container16">
            <div class="case-study-container17">
              <h2 class="case-study-text17 thq-heading-2">
                <slot name="heading3">
                  <!--Default content for heading3-->
                  <div class="case-study-fragment18">
                    <span class="case-study-text31">46%</span>
                  </div>
                </slot>
              </h2>
              <span class="case-study-text18 thq-body-small">
                <slot name="text4">
                  <!--Default content for text4-->
                  <div class="case-study-fragment13">
                    <span class="case-study-text24">Loan to Value (LTV)</span>
                  </div>
                </slot>
              </span>
            </div>
            <div class="case-study-container18">
              <h2 class="case-study-text19 thq-heading-2">
                <slot name="heading4">
                  <!--Default content for heading4-->
                  <div class="case-study-fragment15">
                    <span class="case-study-text26">
                      First Charge and Debenture
                    </span>
                  </div>
                </slot>
              </h2>
              <span class="case-study-text20 thq-body-small">
                <slot name="text5">
                  <!--Default content for text5-->
                  <div class="case-study-fragment11">
                    <span class="case-study-text22">
                      Secured Asset-Backed Lending
                    </span>
                  </div>
                </slot>
              </span>
            </div>
          </div>
        </div>
        <div class="case-study-container19">
          <img
            alt="Property in Shoreditch"
            src="/common/shoreditch-1200w.jpg"
            class="case-study-image thq-img-ratio-16-9"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CaseStudy',
  props: {
    rootClassName: String,
  },
  data() {
    return {
      rawlh0o: ' ',
      rawfmu7: ' ',
    }
  },
}
</script>

<style scoped>
.case-study-section {
  position: relative;
  padding-top: var(--dl-space-space-fourunits);
  margin-bottom: 0px;
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: var(--dl-color-scheme-celadon);
}
 
.case-study-container10 {
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
 
.case-study-max-width {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  justify-content: flex-start;
}
 
.case-study-container11 {
  gap: var(--dl-space-space-oneandhalfunits);
  align-self: center;
  align-items: flex-start;
}
 
.case-study-container12 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.case-study-text11 {
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
}
 
.case-study-container13 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.case-study-container14 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.case-study-text13 {
  font-family: "Bricolage Grotesque";
}
 
.case-study-text14 {
  font-family: "Bricolage Grotesque";
}
 
.case-study-container15 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.case-study-text15 {
  font-family: "Bricolage Grotesque";
}
 
.case-study-text16 {
  font-family: "Bricolage Grotesque";
}
 
.case-study-container16 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.case-study-container17 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.case-study-text17 {
  font-family: "Bricolage Grotesque";
}
 
.case-study-text18 {
  font-family: "Bricolage Grotesque";
}
 
.case-study-container18 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.case-study-text19 {
  font-size: 22px;
  font-family: "Bricolage Grotesque";
}
 
.case-study-text20 {
  font-family: "Bricolage Grotesque";
}
 
.case-study-container19 {
  max-width: 50%;
}
 
.case-study-image {
  width: 533px;
  height: 380px;
}
 
.case-study-fragment10 {
  display: contents;
}
 
.case-study-text21 {
  display: inline-block;
}
 
.case-study-fragment11 {
  display: contents;
}
 
.case-study-text22 {
  display: inline-block;
}
 
.case-study-fragment12 {
  display: contents;
}
 
.case-study-text23 {
  display: inline-block;
}
 
.case-study-fragment13 {
  display: contents;
}
 
.case-study-text24 {
  display: inline-block;
}
 
.case-study-fragment14 {
  display: contents;
}
 
.case-study-text25 {
  display: inline-block;
}
 
.case-study-fragment15 {
  display: contents;
}
 
.case-study-text26 {
  display: inline-block;
}
 
.case-study-fragment16 {
  display: contents;
}
 
.case-study-text27 {
  display: inline-block;
}
 
.case-study-fragment17 {
  display: contents;
}
 
.case-study-text28 {
  display: inline-block;
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
}
 
.case-study-text30 {
  color: var(--dl-color-scheme-white);
}
 
.case-study-fragment18 {
  display: contents;
}
 
.case-study-text31 {
  display: inline-block;
}
 
.case-study-fragment19 {
  display: contents;
}
 
.case-study-text32 {
  display: inline-block;
}
 
.case-study-fragment20 {
  display: contents;
}
 
.case-study-text33 {
  display: inline-block;
}
 

 
@media(max-width: 1200px) {
  .case-study-text11 {
    font-size: 42px;
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 700;
    line-height: 1.25;
  }
  .case-study-text28 {
    font-size: 42px;
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 700;
    line-height: 1.25;
  }
}
 
@media(max-width: 991px) {
  .case-study-max-width {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .case-study-container19 {
    width: 100%;
    max-width: 100%;
  }
  .case-study-image {
    width: 100%;
  }
}
 
@media(max-width: 767px) {
  .case-study-section {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
}
 
@media(max-width: 479px) {
  .case-study-image {
    width: 100%;
  }
  .case-study-text28 {
    font-family: Bricolage Grotesque;
  }
  .case-study-text30 {
    color: var(--dl-color-scheme-white);
  }
}
</style>
