<template>
  <div class="projects-container">
    <app-header></app-header>
    <div class="projects-hero hero-container section-container">
      <div class="projects-max-width1 max-width">
        <div class="projects-content">
          <span class="projects-subtitle">OUR EXPERIENCE</span>
          <h1 class="projects-title">
            <span>Completed </span>
            <span class="projects-text11">Projects</span>
          </h1>
        </div>
      </div>
    </div>
    <div class="projects-section section-container">
      <div class="projects-max-width2 max-width">
        <recent-project-cards>
          <template v-slot:text>
            <div class="projects-fragment10">
              <span class="projects-text12">bridge loan in ruislip</span>
            </div>
          </template>
        </recent-project-cards>
      </div>
    </div>
    <app-faq>
      <template v-slot:text2>
        <div class="projects-fragment11">
          <span class="projects-text13">lEARN MORE ABOUT PLOTPOINT </span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="projects-fragment12">
          <span class="projects-text14">
            How does PlotPoint match originators with funders?
          </span>
        </div>
      </template>
      <template v-slot:text4>
        <div class="projects-fragment13">
          <span class="projects-text15">
            PlotPoint uses a proprietary algorithm and thorough due diligence
            process to connect loan originators with suitable funders. Our team
            and platform evaluates project details, risk appetite, and funding
            preferences to ensure the best possible match, delivering quick and
            efficient connections.
          </span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="projects-fragment14">
          <span class="projects-text16">
            What types of loans do we offer on the platform?
          </span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="projects-fragment15">
          <span class="projects-text17">
            PlotPoint focuses on property-backed loans, including bridging finance
            and development finance. These loans are secured against real assets,
            offering both originators and funders security and flexibility in
            their financial projects.
          </span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="projects-fragment16">
          <span class="projects-text18">Is my investment secure?</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="projects-fragment17">
          <span class="projects-text19">
            While all investments on PlotPoint are secured by real, tangible
            assets and undergo rigorous due diligence—including property
            valuations and borrower assessments—your capital is at risk and not
            protected by the FSCS scheme. There is a possibility of losing some or
            all of your principal and interest.
          </span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="projects-fragment18">
          <span class="projects-text20">Do you have an iOS or Android app?</span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="projects-fragment19">
          <span class="projects-text21">
            Yes! PlotPoint offers a mobile app available on both iOS and Android,
            allowing you to monitor your investments or loans in real time,
            anytime, and anywhere.
          </span>
        </div>
      </template>
      <template v-slot:heading1>
        <div class="projects-fragment20">
          <span class="projects-text22">
            <span class="projects-text23">
              Frequently Asked
              <span v-html="rawjhya"></span>
            </span>
            <br />
            <span class="projects-text25">Questions</span>
          </span>
        </div>
      </template>
    </app-faq>
    <app-footer>
      <template v-slot:text>
        <div class="projects-fragment21">
          <span class="projects-text26">Learn More</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="projects-fragment22">
          <span class="projects-text27">About us</span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="projects-fragment23">
          <span class="projects-text28">Private Credit</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="projects-fragment24">
          <span class="projects-text29">Partner with Us</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="projects-fragment25">
          <span class="projects-text30">Contact</span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="projects-fragment26">
          <span class="projects-text31">App</span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="projects-fragment27">
          <span class="projects-text32">Download iOS app</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="projects-fragment28">
          <span class="projects-text33">Download Android app</span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="projects-fragment29">
          <span class="projects-text34">Originator Portal</span>
        </div>
      </template>
      <template v-slot:link13>
        <div class="projects-fragment30">
          <span class="projects-text35">Your Capital is at Risk</span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="projects-fragment31">
          <span class="projects-text36">Resources</span>
        </div>
      </template>
      <template v-slot:text11>
        <div class="projects-fragment32">
          <span class="projects-text37">Funder Terms and Conditions</span>
        </div>
      </template>
      <template v-slot:text13>
        <div class="projects-fragment33">
          <span class="projects-text38">Disclaimer</span>
        </div>
      </template>
      <template v-slot:text14>
        <div class="projects-fragment34">
          <span class="projects-text39">Privacy Policy</span>
        </div>
      </template>
      <template v-slot:text15>
        <div class="projects-fragment35">
          <span class="projects-text40">Products</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="projects-fragment36">
          <span class="projects-content1">
            <span>Fourth Floor, 70 Baker Street, </span>
            <span>London, W1U 7DL</span>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content2>
        <div class="projects-fragment37">
          <span class="projects-content2">
            <span class="projects-text44">Phone: </span>
            <a href="tel:+442081547035" class="projects-link1">+</a>
            <a href="tel:+442081547035" class="projects-link2">44 208 154 7035</a>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content3>
        <div class="projects-fragment38">
          <span class="projects-content3">
            <span class="projects-text46">
              ©2024 PlotPoint Limited. All rights reserved.
            </span>
            <br />
            <span>
              Registered in England &amp; Wales (Reg Number: 15914598).
              <span v-html="rawx85n"></span>
            </span>
          </span>
        </div>
      </template>
      <template v-slot:contentTitle1>
        <div class="projects-fragment39">
          <span class="projects-text49">PlotPoint Limited</span>
        </div>
      </template>
    </app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import RecentProjectCards from '../components/recent-project-cards'
import AppFaq from '../components/faq'
import AppFooter from '../components/footer'

export default {
  name: 'Projects',
  props: {},
  components: {
    AppHeader,
    RecentProjectCards,
    AppFaq,
    AppFooter,
  },
  data() {
    return {
      rawjhya: ' ',
      rawx85n: ' ',
    }
  },
  metaInfo: {
    title: 'Our Projects - PlotPoint',
    meta: [
      {
        name: 'description',
        content:
          "Discover PlotPoint's inspiring property projects. Browse success stories and innovative developments that showcase our expertise in property finance.",
      },
      {
        property: 'og:title',
        content: 'Our Projects - PlotPoint',
      },
      {
        property: 'og:description',
        content:
          "Discover PlotPoint's inspiring property projects. Browse success stories and innovative developments that showcase our expertise in property finance.",
      },
    ],
  },
}
</script>

<style scoped>
.projects-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.projects-hero {
  padding-bottom: 0px;
}
 
.projects-max-width1 {
  align-items: center;
}
 
.projects-content {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
 
.projects-subtitle {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
 
.projects-title {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-unit);
}
 
.projects-text11 {
  color: var(--dl-color-scheme-celadon);
}
 
.projects-section {
  position: relative;
  padding-top: var(--dl-space-space-fourunits);
  margin-bottom: 0px;
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: var(--dl-color-theme-secondary1);
}
 
.projects-max-width2 {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.projects-fragment10 {
  display: contents;
}
 
.projects-text12 {
  display: inline-block;
}
 
.projects-fragment11 {
  display: contents;
}
 
.projects-text13 {
  display: inline-block;
}
 
.projects-fragment12 {
  display: contents;
}
 
.projects-text14 {
  display: inline-block;
}
 
.projects-fragment13 {
  display: contents;
}
 
.projects-text15 {
  display: inline-block;
}
 
.projects-fragment14 {
  display: contents;
}
 
.projects-text16 {
  display: inline-block;
}
 
.projects-fragment15 {
  display: contents;
}
 
.projects-text17 {
  display: inline-block;
}
 
.projects-fragment16 {
  display: contents;
}
 
.projects-text18 {
  display: inline-block;
}
 
.projects-fragment17 {
  display: contents;
}
 
.projects-text19 {
  display: inline-block;
}
 
.projects-fragment18 {
  display: contents;
}
 
.projects-text20 {
  display: inline-block;
}
 
.projects-fragment19 {
  display: contents;
}
 
.projects-text21 {
  display: inline-block;
}
 
.projects-fragment20 {
  display: contents;
}
 
.projects-text22 {
  display: inline-block;
}
 
.projects-text23 {
  font-family: "Urbanist";
}
 
.projects-text25 {
  color: var(--dl-color-scheme-celadon);
  font-family: "Urbanist";
}
 
.projects-fragment21 {
  display: contents;
}
 
.projects-text26 {
  display: inline-block;
}
 
.projects-fragment22 {
  display: contents;
}
 
.projects-text27 {
  display: inline-block;
}
 
.projects-fragment23 {
  display: contents;
}
 
.projects-text28 {
  display: inline-block;
}
 
.projects-fragment24 {
  display: contents;
}
 
.projects-text29 {
  display: inline-block;
}
 
.projects-fragment25 {
  display: contents;
}
 
.projects-text30 {
  display: inline-block;
}
 
.projects-fragment26 {
  display: contents;
}
 
.projects-text31 {
  display: inline-block;
}
 
.projects-fragment27 {
  display: contents;
}
 
.projects-text32 {
  display: inline-block;
}
 
.projects-fragment28 {
  display: contents;
}
 
.projects-text33 {
  display: inline-block;
}
 
.projects-fragment29 {
  display: contents;
}
 
.projects-text34 {
  display: inline-block;
}
 
.projects-fragment30 {
  display: contents;
}
 
.projects-text35 {
  display: inline-block;
}
 
.projects-fragment31 {
  display: contents;
}
 
.projects-text36 {
  display: inline-block;
}
 
.projects-fragment32 {
  display: contents;
}
 
.projects-text37 {
  display: inline-block;
}
 
.projects-fragment33 {
  display: contents;
}
 
.projects-text38 {
  display: inline-block;
}
 
.projects-fragment34 {
  display: contents;
}
 
.projects-text39 {
  display: inline-block;
}
 
.projects-fragment35 {
  display: contents;
}
 
.projects-text40 {
  display: inline-block;
}
 
.projects-fragment36 {
  display: contents;
}
 
.projects-content1 {
  display: inline-block;
}
 
.projects-fragment37 {
  display: contents;
}
 
.projects-content2 {
  display: inline-block;
}
 
.projects-text44 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
 
.projects-link1 {
  text-decoration: underline;
}
 
.projects-link2 {
  text-decoration: underline;
}
 
.projects-fragment38 {
  display: contents;
}
 
.projects-content3 {
  display: inline-block;
}
 
.projects-text46 {
  font-weight: 700;
}
 
.projects-fragment39 {
  display: contents;
}
 
.projects-text49 {
  display: inline-block;
}
 
@media(max-width: 1200px) {
  .projects-text11 {
    color: var(--dl-color-scheme-celadon);
  }
}
 
@media(max-width: 991px) {
  .projects-content {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }
  .projects-max-width2 {
    flex-direction: column;
  }
}
 
@media(max-width: 767px) {
  .projects-max-width1 {
    flex-direction: column-reverse;
  }
  .projects-content {
    width: 100%;
    margin-right: 0px;
  }
}
</style>
