<template>
  <div
    class="recent-projects-section section-container"
    v-bind:class="rootClassName"
  >
    <div class="recent-projects-max-width max-width">
      <h1 class="recent-projects-text1">
        <slot name="heading">
          <!--Default content for heading-->
          <div class="recent-projects-fragment2">
            <span class="recent-projects-text3">Recent Projects</span>
          </div>
        </slot>
      </h1>
      <recent-project-cards>
        <template v-slot:text>
          <div class="recent-projects-fragment1">
            <span class="recent-projects-text2">bridge loan in ruislip</span>
          </div>
        </template>
      </recent-project-cards>
    </div>
  </div>
</template>

<script>
import RecentProjectCards from './recent-project-cards'

export default {
  name: 'RecentProjects',
  props: {
    rootClassName: String,
  },
  components: {
    RecentProjectCards,
  },
}
</script>

<style scoped>
.recent-projects-section {
  position: relative;
  padding-top: var(--dl-space-space-fourunits);
  margin-bottom: 0px;
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: var(--dl-color-scheme-celadon);
}
 
.recent-projects-max-width {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.recent-projects-text1 {
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
 
.recent-projects-fragment1 {
  display: contents;
}
 
.recent-projects-text2 {
  display: inline-block;
}
 
.recent-projects-fragment2 {
  display: contents;
}
 
.recent-projects-text3 {
  display: inline-block;
}
 

 

 
@media(max-width: 991px) {
  .recent-projects-max-width {
    flex-direction: column;
  }
}
 
@media(max-width: 479px) {
  .recent-projectsroot-class-name {
    padding-left: 0px;
  }
}
</style>
